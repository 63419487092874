<template>
	<div class="contents">
		<div class="content">
			<div class="banners"  :style="'background-image: url(' + require('../assets/faqbanner.jpg') +')'">
				<h4>{{ $t('ji-shu-zhi-chi') }}</h4>
				<div class="tabbar">
					<div class="m">
						<div>
							<router-link to='/service'>
								<img src="../assets/download1.png" />
								<span>{{ $t('shou-hou-fu-wu') }}</span>
							</router-link>
						</div>
						<div class="on">
							<router-link to='/data_down'>
								<img src="../assets/download.png" />
								<span>{{ $t('zi-liao-xia-zai') }}</span>
							</router-link>
						</div>
						<div>
							<router-link to='/support'>
								<img src="../assets/download2.png" />
								<span>FAQ</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="main_down">
				<div class="m">
					<ul class="tab">
						<li class="on">{{ $t('gui-ge-biao') }}</li>
						<li>{{ $t('yong-hu-shou-ce') }}</li>
						<li>{{ $t('qu-dong') }}</li>
						<li>{{ $t('mu-lu') }}</li>
					</ul>
					<div class="icon">
						<ul>
							<li><a href="#">
								<img src="../assets/datadown_07.jpg"/>
								<p>M-TXX</p>
								<span>MT-XXX Specifications.pdf</span>
								<em>更新时间：2022-01-09</em>
							</a></li>
							<li><a href="#">
								<img src="../assets/datadown_07.jpg"/>
								<p>M-TXX</p>
								<span>MT-XXX Specifications.pdf</span>
								<em>更新时间：2022-01-09</em>
							</a></li>
							<li><a href="#">
								<img src="../assets/datadown_07.jpg"/>
								<p>M-TXX</p>
								<span>MT-XXX Specifications.pdf</span>
								<em>更新时间：2022-01-09</em>
							</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<icondown @scrolltops='scrolltop'></icondown>
	</div>
</template>

<script>
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			scrolltop() {
					document.documentElement.scrollTop = 0
			},
			link() {
				
			},
			handleScroll() {
				if((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
					this.flag = true
				} else {
					this.flag = false
				}
			}
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style>
	.swiper-pagination-bullet-active {
		background: #E3DCB2 !important;
	}
</style>