<template>
  <div class="custom-header">
    <div class="search_fadein" v-if="searchflag" @mouseleave="hideItem">
      <div class="box">
        <div class="grow">
          <div class="head">
            <p>{{ $t('sousuo') }} {{ searchValue }} {{ searchTotal }}个</p>
            <div class="more" v-if="searchList.length > 0">
              <router-link to="/productList">{{ $t('cha-kan-geng-duo') }}</router-link>
            </div>
          </div>
          <ul>
            <li v-for="(item, index) in searchList" :key="index">
              <router-link :to="`/goodInfo/${item.id}`">
                <div class="pic"><img src="../assets/searchs.png" /></div>
                <p>{{ getContent(item.goodsLanguages).name }}</p>
                <div class="btn">{{ $t('li-ji-zi-xun') }}</div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mobile_navbar" :class="headerflag ? 'on' : ''">
      <div class="box">
        <img src="../assets/close_circle.png" @click="headerflag = false" />
        <div style="clear: both"></div>
        <ul>
          <li @click="link(item.links, index)" v-for="(item, index) in links" :key="index">
            <a>{{ item.name }}</a>
            <div v-if="item.flag">
              <a href="#">{{ $t('header.companyProfile') }}</a>
              <a href="#">{{ $t('gong-si-wen-hua') }}</a>
              <a href="#">{{ $t('header.newsCenter') }}</a>
              <a href="#">{{ $t('header.qualificationCertificate') }}</a>
              <a href="#">{{ $t('header.contactUs') }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="header" :class="flag ? 'on' : ''">
      <div class="m">
        <div class="left">
          <router-link to="/">
            <img src="../assets/xcadd11.png" class="logo" />
          </router-link>
          <img src="../assets/menu.png" class="menu" @click="headerflag = true" />
          <select class="m-language_box" v-model="langKey" @change="handleChangeLanguage">
            <option value="zh">中国(简体中文)</option>
            <option value="en">English</option>
            <option value="ewen">русский язык</option>
            <option value="riwen">日本語</option>
            <option value="ptywen">Português</option>
          </select>
          <ul>
            <li
              @mouseover="showItem(item.links, index)"
              @click="link(item.links, index)"
              :class="activityPath == item.links ? 'on' : ''"
              v-for="(item, index) in links"
              :key="index"
            >
              <a>{{ item.name }}</a>
              <div v-if="item.flag">
                <router-link to="/about">{{ $t('header.companyProfile') }}</router-link>
                <router-link to="/companyCulture">{{ $t('header.companyCulture') }}</router-link>
                <router-link to="/news">{{ $t('header.newsCenter') }}</router-link>
                <router-link to="/zs">{{ $t('header.qualificationCertificate') }}</router-link>
                <router-link to="/concatUs">{{ $t('header.contactUs') }}</router-link>
              </div>
            </li>
          </ul>
          <div class="searhx" v-if="searchflag">
            <input
              type="text"
              :placeholder="$t('qing-shu-ru')"
              name=""
              id=""
              v-model="searchValue"
              v-on:keyup.enter="searchFn"
            />
            <img
              @click="
                searchflag = false;
                searchValue = '';
                searchList = [];
                searchTotal = 0;
              "
              src="../assets/closex.png"
            />
          </div>
          <img src="../assets/search.png" @click="searchflag = true" class="search" />
        </div>
        <select class="language_box" v-model="langKey" @change="handleChangeLanguage">
          <option value="zh">中国(简体中文)</option>
          <option value="en">English</option>
          <option value="ewen">русский язык</option>
          <option value="riwen">日本語</option>
          <option value="ptywen">Português</option>
        </select>
      </div>
    </div>

    <div class="main_case_list1 m top" v-if="showProduct" @mouseover="showItem('/product')" @mouseleave="hideItem">
      <h3>{{ $t('chan-pin-lei-xing') }}</h3>
      <ul>
        <li v-for="(item, index) in productList" :key="index">
          <router-link :to="`/productList/${item.id}`">
            <div class="pic">
              <img :src="item.productCpmImage" />
            </div>
            <div class="grow">
              <p>{{ item.productLanguages[0].name }}</p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="main_case_list1 m top" v-if="showSolution" @mouseover="showItem('/cases')" @mouseleave="hideItem">
      <h3>{{ $t('hang-ye-jie-jue-fang-an') }}</h3>
      <ul>
        <li>
          <a @click="handleToPage('case1')">
            <div class="pic">
              <img src="../assets/caseicon_03.png" />
            </div>
            <div class="grow">
              <p>{{ $t('zi-dong-hua') }}</p>
              <span>ssd/001</span>
              <span>sata/001</span>
              <span>dom/001</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="handleToPage('case2')">
            <div class="pic">
              <img src="../assets/case_icon3_03.png" />
            </div>
            <div class="grow">
              <p>{{ $t('jiao-tong-yun-shu') }}</p>
              <span>ssd/001</span>
              <span>sata/001</span>
              <span>dom/001</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="handleToPage('case3')">
            <div class="pic">
              <img src="../assets/case_icon4_03.png" />
            </div>
            <div class="grow">
              <p>{{ $t('wu-lian-wang') }}</p>
              <span>ssd/001</span>
              <span>sata/001</span>
              <span>dom/001</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="handleToPage('case4')">
            <div class="pic">
              <img src="../assets/case_icon1_03.png" />
            </div>
            <div class="grow">
              <p>{{ $t('jian-kong') }}</p>
              <span>ssd/001</span>
              <span>sata/001</span>
              <span>dom/001</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="handleToPage('case5')">
            <div class="pic">
              <img src="../assets/case_icon6_03.png" />
            </div>
            <div class="grow">
              <p>{{ $t('yi-liao-hang-ye') }}</p>
              <span>ssd/001</span>
              <span>sata/001</span>
              <span>dom/001</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="handleToPage('case6')">
            <div class="pic">
              <img src="../assets/case_icon7_03.png" />
            </div>
            <div class="grow">
              <p>{{ $t('rong-zi') }}</p>
              <span>ssd/001</span>
              <span>sata/001</span>
              <span>dom/001</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="handleToPage('case7')">
            <div class="pic">
              <img src="../assets/case_icon5_03.png" />
            </div>
            <div class="grow">
              <p>{{ $t('you-xi-hang-ye') }}</p>
              <span>ssd/001</span>
              <span>sata/001</span>
              <span>dom/001</span>
            </div>
          </a>
        </li>
        <li>
          <a @click="handleToPage('case8')">
            <div class="pic">
              <img src="../assets/case_icon2_03.png" />
            </div>
            <div class="grow">
              <p>{{ $t('jiao-yu-hang-ye') }}</p>
              <span>ssd/001</span>
              <span>sata/001</span>
              <span>dom/001</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="null"></div>
  </div>
</template>

<script>
import { getProductListByDialog } from '@/api/product';
import { getGoodsList } from '@/api/good';
export default {
  name: 'headers',
  data() {
    return {
      searchflag: false,
      flag: false,
      headerflag: false,
      current: 0,
      langKey: '',
      showProduct: false,
      showSolution: false,
      productList: [],
      activityPath: '/',
      searchValue: '',
      searchList: [],
      searchTotal: 0,
      language: {
        zh: 1,
        en: 2,
        ewen: 3,
        riwen: 4,
        ptywen: 5
      },
    };
  },
  created() {
    this.getProductList();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.current = sessionStorage.getItem('isSelect');
    let key = localStorage.getItem('langkey');
    this.langKey = key;
    this.$i18n.locale = this.langKey;
  },
  watch: {
    $route(to) {
      this.activityPath = to.path;
    },
  },
  methods: {
    login() {
      this.$emit('logins');
    },
    reg() {
      this.$emit('regs');
    },
    async getProductList() {
      try {
        let params = {
          curPage: 1,
          numPerPage: 100,
          // languageId: localStorage.getItem('languageID') || 1,
          languageId: this.languageConfigList[localStorage.getItem('langkey')],
          type: '',
        };
        const res = await getProductListByDialog(params);
        console.log({ res });
        this.productList = res.data?.data.slice(0, 8);
        console.log(';;;;;;', this.productList);
      } catch (error) {
        console.log(error);
      }
    },
    // 搜索接口
    async searchFn() {
      let params = {
        name: this.searchValue,
      };
      let res = await getGoodsList(params);
      if (res.data) {
        this.searchList = res.data.data.slice(0, 8);
        this.searchTotal = res.data.totalRows;
      }
    },
    getContent(data) {
      let res = data.find((ele) => ele.languageId == this.languageConfigList[localStorage.getItem('langkey')]);
      return res || data[0] || {};
    },
    handleToPage(val) {
      if (this.$route.path == '/cases') {
        document.getElementById(val).scrollIntoView();
      } else {
        this.$router.push({
          path: `/cases?id=${val}`,
        });
      }
    },
    link(links, index) {
      sessionStorage.setItem('isSelect', index);
      this.current = index;
      this.$router.push(links).catch(() => {});
      this.headerflag = false;
    },
    handleScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    handleChangeLanguage(e) {
      console.log('chufale :', e.target.value);
      localStorage.setItem('langkey', e.target.value);
      localStorage.setItem('languageID', this.language[e.target.value]);
      // this.$i18n.locale = e.target.value;
      window.location.reload()
    },
    showItem(item) {
      console.log(item);
      if (item == '/product') {
        this.showProduct = true;
        this.showSolution = false;
      } else if (item == '/cases') {
        this.showSolution = true;
        this.showProduct = false;
      } else {
        this.showSolution = false;
        this.showProduct = false;
      }
    },
    hideItem() {
      this.showProduct = false;
      this.showSolution = false;
      this.searchflag = false;
    },
  },
  computed: {
    links() {
      const arr = [
        {
          name: this.$t('header.index'),
          links: '/',
          flag: false,
        },
        {
          name: this.$t('header.productCenter'),
          links: '/product',
          flag: false,
        },
        {
          name: this.$t('header.solution'),
          links: '/cases',
          flag: false,
        },
        {
          name: this.$t('header.OEMODM'),
          links: '/odm',
          flag: false,
        },
        {
          name: this.$t('header.investmentPromotion'),
          links: '/brand',
          flag: false,
        },
        {
          name: this.$t('header.technicalSupport'),
          links: '/service',
          flag: false,
        },
        {
          name: this.$t('header.aboutUs'),
          links: '',
          flag: true,
        },
      ];
      return arr;
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.custom-header {
  .language_box {
    border-radius: 17px;
    border: 1px solid #ceb1b1;
    background-color: transparent;
    font-size: 16px;
    color: #ceb1b1;
    padding: 4px 10px;
  }
  .m-language_box {
    border-radius: 17px;
    border: 1px solid #ceb1b1;
    background-color: transparent;
    font-size: 16px;
    color: #ceb1b1;
    padding: 4px 10px;
  }
  @media (min-width: 801px) {
    .language_box {
      display: block;
    }
    .m-language_box {
      display: none;
    }
  }

  @media (max-width: 800px) {
    .language_box {
      display: none;
    }
    .m-language_box {
      display: block;
      position: absolute;
      right: 75px;
    }
  }
}
.top {
  z-index: 9999;
  margin-top: 10px;
}
</style>