<template>
  <div>
    <div class="main_form">
      <div class="map">
        <baidu-map :center="{ lng: 114.038414, lat: 22.670485 }" :zoom="17" style="height: 796px"></baidu-map>
      </div>
      <div class="form">
        <p>
          What we can do for you?
        </p>
        <div class="lab">
          <input type="text" v-model="postData.name" :placeholder="$t('username')" name="" id="" />
          <input type="text" v-model="postData.email" :placeholder="$t('email')" name="" id="" />
        </div>
        <div class="lab">
          <!-- <el-select v-model="quhao" slot="prepend" style="min-width:120px;marginRight:28px" placeholder="请选择国家区号">
            <el-option v-for="(item, index) in data" :label="`+${item.tel}_${item.en}`" :value="item.tel" :key="index"></el-option>
          </el-select> -->
          <select v-model="quhao" style="width:47%">
            <option v-for="(item, index) in data" :label="`+${item.tel}_${item.en}`" :value="item.tel" :key="index"></option>
          </select>
          <input :placeholder="$t('please-input-phone')" v-model="phone" />
        </div>
        <div class="lab">
          <textarea name="" v-model="postData.content" :placeholder="$t('input-content')" rows="" cols=""></textarea>
        </div>
        <div class="submit" @click="postFeedBack">{{ $t('ti-jiao') }}</div>
      </div>
    </div>

    <div class="footer">
      <div class="m">
        <img src="../assets/homeadd_23.jpg" class="logo" />
        <div class="link">
          <ul>
            <li>{{ $t('gong-si') }}</li>
            <li>
              <router-link to="/product">{{ $t('chan-pin-zhong-xin') }}</router-link>
            </li>
            <li>
              <router-link to="/cases">{{ $t('jie-jue-fang-an') }}</router-link>
            </li>
            <li>
              <router-link to="/odm">ODM/OEM</router-link>
            </li>
            <li>
              <router-link to="/brand">{{ $t('dai-li-pin-pai') }}</router-link>
            </li>
          </ul>
          <ul>
            <li>{{ $t('chan-pin') }}</li>
            <li>
              <router-link to="/product">{{ $t('gu-tai-ying-pan') }}</router-link>
            </li>
            <li>
              <router-link to="/product">{{ $t('nei-cun-tiao') }}</router-link>
            </li>
            <li>
              <router-link to="/product">{{ $t('u-pan') }}</router-link>
            </li>
          </ul>
          <ul>
            <li>{{ $t('ji-shu-zhi-chi') }}</li>
            <li>
              <router-link to="/concatUs">{{ $t('contact-us') }}</router-link>
            </li>
            <li>
              <router-link to="/service">{{ $t('help-center') }}</router-link>
            </li>
            <li>
              <router-link to="/support">{{ $t('FAQs') }}</router-link>
            </li>
          </ul>
          <ul>
            <li>{{ $t('lian-xi-fang-shi') }}</li>
            <li>
                <img src="../assets/icon-phone.png" />
                <span class="ml5">{{ $t('dian-hua') }}：+86 13510354835（WhatsApp）</span>
            </li>
            <li>
                <img src="../assets/email.png" />
                <span class="ml5">{{ $t('you-xiang') }}：manager@etopso-cn.com</span>
            </li>
            <li>
                <img src="../assets/iconaddress.png" />
                <p class="ml5 longWord">3/F.BuildingD,YoudingEnterpriseInnovationPark,LonghuaNewDistrict,Shenzhen,China</p>
            </li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100083084033563"><img src="../assets/iconf.png" /></a>
              <a href="https://twitter.com/etopso"><img src="../assets/icont.png" /></a>
              <a href="#"><img src="../assets/icondes1.png" /></a>
              <a href="#"><img src="../assets/iconin.png" /></a>
              <a href="#"><img src="../assets/icondes.png" /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addFeedback } from '@/api/feedback.js'
import { phoneData } from '../utils/data';
export default {
  name: 'footers',
  data() {
    return {
      postData: {
        companyName: "",
        content: "",
        country: "china",
        ctime: "",
        email: "",
        name: "",
      },
      phone: "",
      data: [],
      quhao: '86'
    }
  },
  async mounted() {
    this.data = phoneData
  },
  methods: {
    async postFeedBack() {
      console.log('dddd', this.postData, this.quhao, this.phone)
      if (this.phone == '' || this.postData.name == '' || this.postData.content == '' || this.postData.name == '') {
        this.$message.error('内容不能为空');
        return
      }
      let reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (reg.test(this.postData.email) == false) {
        this.$message.error('邮箱格式不正确');
        return
      }
      let phoneReg = /^[0-9]+$/;
      if (!phoneReg.test(this.phone)) {
        this.$message.error('手机格式不正确');
        return
      }
      this.postData.phone = `(+${this.quhao})${this.phone}`
      let res = await addFeedback(this.postData);
      console.log('resss', res)
      if (res.code == 200) {
        this.$message.success('提交成功')
        window.location.reload()
      }
    }
  }
};
</script>

<style lang="less" scoped>
.ml5 {
  margin-left: 5px;
}
.longWord {
  font-size: 12px;
}
</style>