import request from '@/api/request'
import qs from 'qs'

export function getNewsData(data) {
  return request({
    url: '/client/getNewsList',
    method: 'get',
    params: data
  })
}


export function getNewsById(data) {
  return request({
    url: '/client/getNewsById',
    method: 'post',
    data: qs.stringify(data)
  })
}