import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js';
import i18n from './locales/i18n.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
import './assets/style/css.css';
import './assets/style/reset.css';
import './utils/rem.js'
import './assets/style/media.css';
import headers from '@/components/headers';
import footers from '@/components/footers';
import icondown from '@/components/icondown';
Vue.component('headers', headers)
Vue.component('footers', footers)
Vue.component('icondown', icondown)
Vue.use(ElementUI);

Vue.use(BaiduMap, {
	ak: "rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR"
})
Vue.config.productionTip = false
Vue.prototype.languageConfigList = {
	'zh': '1',
	'en': '2',
	'ewen': '3',
	'riwen': '4',
	'ptywen': '5'
}
new Vue({
	router,
	i18n,
	render: h => h(App),
}).$mount('#app')