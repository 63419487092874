<template>
  <div class="contents custom-list">
    <div class="content">
      <div class="banners" :style="'background-image: url(' + require('../assets/case.jpg') + ')'"></div>
      <div class="main_product_box">
        <div class="m">
          <div class="left">
            <div class="product_center"  id="show">
              <h3>{{ $t('chan-pin-zhong-xin') }}</h3>
              <ul class="pc">
                <li @click="setAllGoodItem(-1)" :class="currentIndex == -1 ? 'isActive' : ''">
                  <div class="line">
                    <p>{{ $t('quan-bu-shang-pin') }}</p>
                  </div>
                </li>

                <li
                  v-for="(item, index) in productList"
                  :key="index"
                  @click="setGoodItem(item, index)"
                  :class="currentIndex == index ? 'isActive' : ''"
                >
                  <div class="line">
                    <p>{{ item.productLanguages[0].name }}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="product_center">
              <h3>{{ $t('zui-xin-chan-pin') }}</h3>
              <ul class="nc">
                <li v-for="(item, index) in latestProductList" :key="index">
                  <router-link :to="`/goodInfo/${item.id}`">
                    <div class="pic">
                      <img :src="item.gheadImages" />
                    </div>
                    <div class="grow">
                      <p>{{ item.goodsLanguages[0].name }}</p>
                      <div>
                        <span>{{ $t('cha-kan-xiang-qing') }}</span>
                        <img src="../assets/productlist_11.jpg" />
                      </div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="right">
            <div class="line_title">{{ currentProduct }}</div>
            <ul>
              <li v-for="(item, index) in goodList" :key="index">
                <router-link :to="`/goodInfo/${item.id}`">
                  <div class="pic">
                    <img :src="item.gheadImages" />
                  </div>
                  <p>{{ item.goodsLanguages[0].name }}</p>
                  <!-- <span>US$10.99 - US$64.00/pieces</span>
                  <span>20.0 pieces(Min. Order)</span> -->
                </router-link>
              </li>
            </ul>
            <div class="page_box">
              <div>
                <a
                  v-for="item in totalPages"
                  :key="item"
                  :class="currentPage == item ? 'on' : ''"
                  @click="setPage(item)"
                >
                  {{ item }}
                </a>
              </div>
              <p>
                {{ $t('gong') }}
                <span>{{ totalPages }}</span>
                {{ $t('ye') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <icondown @scrolltops="scrolltop"></icondown>
  </div>
</template>

<script>
import { getProductList } from '@/api/product';
import { getGoodsList } from '@/api/good';
export default {
  name: 'home',
  data() {
    return {
      top: 0,
      flag: false,
      flag1: false,
      headerflag: false,
      goodList: [],
      currentIndex: 0,
      totalPages: null,
      currentPage: 1,
      productList: [],
      currentProduct: null,
      goodParam: {
        curPage: 1,
        numPerPage: 9,
        languageId: localStorage.getItem('languageID') || 1,
        type: '',
      },
    };
  },
  computed: {
    latestProductList() {
      let arr = JSON.parse(JSON.stringify(this.goodList));
      return arr.splice(0, 3);
    },
  },
  created() {
    // console.log(this.$route.params.productID);
    if (!this.$route.params.productID) {
      this.goodParam.type = '';
      this.setAllGoodItem(-1);
    } else {
      this.goodParam.type = this.$route.params.productID;
    }
    this.getGoodsList();
    this.getProductList();
  },
  watch: {
    //监听路由地址的改变
    $route:{
      immediate:true,
      handler(){
          if(this.$route.params.productID){//需要监听的参数
            this.goodParam.type = this.$route.params.productID
            this.getGoodsList();
            this.getProductList();
          }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrolltop() {
      document.documentElement.scrollTop = 0;
    },
    link() {},
    handleScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    async getGoodsList() {
      try {
        const res = await getGoodsList(this.goodParam);
        this.goodList = res?.data?.data;
        this.totalPages = res?.data?.totalPages;
        document.getElementById('show').scrollIntoView();
      } catch (error) {
        console.log(error);
      }
    },
    async getProductList() {
      try {
        let params = {
          curPage: 1,
          numPerPage: 100,
          // languageId: localStorage.getItem('languageID') || 1,
          languageId: this.languageConfigList[localStorage.getItem('langkey')],
          type: '',
        };
        const res = await getProductList(params);
        this.productList = res?.data?.data;
        this.productList.map((item, index) => {
          if (item.productLanguages[0].proId == this.$route.params.productID) {
            this.currentProduct = item.productLanguages[0].name;
            this.currentIndex = index;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    setGoodItem(item, index) {
      this.currentIndex = index;
      this.currentProduct = item.productLanguages[0].name;
      this.goodParam.type = item.productLanguages[0].proId;
      this.getGoodsList(this.goodParam);
    },
    setAllGoodItem(index) {
      this.currentIndex = index;
      this.currentProduct = '全部商品';
      this.goodParam.type = '';
      this.getGoodsList(this.goodParam);
    },
    setPage(index) {
      this.currentPage = index;
      this.goodParam.curPage = index;
      this.getGoodsList(this.goodParam);
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.swiper-pagination-bullet-active {
  background: #e3dcb2 !important;
}
.custom-list {
  .line {
    p {
      opacity: 0.6;
    }
  }
  .isActive {
    p {
      opacity: 1;
    }
  }
}
</style>