<template>
	<div class="contents concatUsContent">
		<div class="content">
			<div class="banners" :style="'height: 750px;padding: 0;background-image: url(' + require('../assets/about.jpg') +')'">
				<h4>{{ $t('header.contactUs') }}</h4>
			</div>
		</div>
		<div class="concatUs-content">
			<ul>
				<li>
					<img src="../assets/icon-phone.png" /> +86 13510354835（WhatsApp）
				</li>
				<li>
					<img src="../assets/email.png" /> manager@etopso-cn.com
				</li>
				<li>
					<img src="../assets/iconaddress.png" /> 
					<span>
						3/F.BuildingD,YoudingEnterpriseInnovationPark
						LonghuaNewDistrict,Shenzhen,China
					</span>
				</li>
			</ul>
			<img src="../assets/qrcode.png" style="marginTop:0.4rem" alt="" srcset="">
		</div>
		
		<icondown @scrolltops='scrolltop'></icondown>
	</div>
</template>

<script>
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			scrolltop() {
				document.documentElement.scrollTop = 0
			},
			link() {

			},
			handleScroll() {
				if((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
					this.flag = true
				} else {
					this.flag = false
				}
			}
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style lang='less' scope>
	.swiper-pagination-bullet-active {
		background: #E3DCB2 !important;
	}
	@media (max-width: 800px) {
		.concatUsContent {
			.banners {
				height: 5rem !important;
			}
			.concatUs-content {
				padding-top: 0.5rem;
				display: flex;
				flex-direction: column;
				ul {
					padding: 0.2rem 0.3rem;
					width: 100%;
					height: 100%;
					margin-right: 0;
					li {
						font-size: 0.1rem;
						margin-bottom: 0.2rem;
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						span {
							margin-left:0.6rem;
							margin-top:-0.4rem
						}
					}
				}
				img {
					margin-top: 0rem;
					margin-right: 0.2rem;
				}
			}
		}
	}
</style>