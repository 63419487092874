import request from '@/api/request'
export function getGoodsList(data) {
  return request({
    url: '/client/getGoodList',
    method: 'get',
    params: data
  })
}

export function getGoodById(data) {
  return request({
    url: '/client/getGoodById',
    method: 'get',
    params: data
  })
}