<template>
  <div class="contents">
    <div class="content">
      <div class="banner">
        <div class="swiper-container" id="swiperIndex">
          <div class="swiper-wrapper">
            <!-- <div class="swiper-slide" :style="'background-image: url(' + require('../assets/banner0.jpg') + ')'"></div> -->
            <div class="swiper-slide" :style="'background-image: url(' + require('../assets/banner1.jpg') + ')'"></div>
            <div class="swiper-slide" :style="'background-image: url(' + require('../assets/banner2.jpg') + ')'"></div>
            <div class="swiper-slide" :style="'background-image: url(' + require('../assets/banner3.jpg') + ')'"></div>
            <div class="swiper-slide" :style="'background-image: url(' + require('../assets/banner4.jpg') + ')'"></div>
            <div class="swiper-slide" :style="'background-image: url(' + require('../assets/banner5.jpg') + ')'"></div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="main_product_class">
        <div class="m">
          <div class="tong_title">
            <p>{{ $t('chan-pin-lei-xing') }}</p>
            <span>{{ $t('best-service') }}</span>
          </div>
          <ul>
            <li
              v-for="(item, index) in typeList"
              :key="index"
              @click="handleClickTab(item.id)"
              :class="current == item.id ? 'on' : ''"
            >
              <div v-if="item.typeName == '硬盘(SSD)'">
                <img src="../assets/addpics2.png" v-if="current != item.id" />
                <img src="../assets/icon2.png" v-if="current == item.id" />
              </div>
              <span v-if="item.typeName == '硬盘(SSD)'">{{ $t('gu-tai-ying-pan') }}</span>

              <div v-if="item.typeName == 'DDR RAM(内存条)'">
                <img src="../assets/Vector.png" v-if="current != item.id" />
                <img src="../assets/addpics3.png" v-if="current == item.id" />
              </div>
              <span v-if="item.typeName == 'DDR RAM(内存条)'">{{ $t('nei-cun-tiao') }}</span>

              <div v-if="item.typeName == 'U盘'">
                <img src="../assets/icon1.png" v-if="current != item.id" />
                <img src="../assets/addpics1.png" v-if="current == item.id" />
              </div>
              <span v-if="item.typeName == 'U盘'">{{ $t('u-pan') }}</span>

              <div v-if="item.typeName == '内存卡'">
                <img src="../assets/neicunka.png" style="height:inherit" v-if="current != item.id" />
                <img src="../assets/neicunkaed.png" style="height:inherit" v-if="current == item.id" />
              </div>
              <span v-if="item.typeName == '内存卡'">{{ $t('nei-cun-kas') }}</span>
            </li>
          </ul>
        </div>
        <div class="box">
          <ul class="m">
            <li v-for="(item, index) in productDataByTab.slice(0, 4)" :key="index">
              <router-link :to="`/productList/${item.id}`">
                <p>{{ getTypeContent(item.productLanguages).name }}</p>
                <span>
                  {{ getTypeContent(item.productLanguages).productDesc }}
                </span>
                <div class="pic">
                  <img :src="item.productImages" />
                </div>
                <div class="more">{{ $t('more-greater-than') }}</div>
              </router-link>
            </li>
          </ul>
          <div class="mores">
            <router-link to="/product">
              <span>{{ $t('more-greater-than') }}</span>
              <img src="../assets/Iconsarrow-right.png" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="main_product">
        <div class="m">
          <div class="tong_title">
            <p>{{ $t('jing-xuan-chan-pin') }}</p>
          </div>
          <ul>
            <li v-if="hotList[0]">
              <router-link :to="`/goodInfo/${hotList[0].id}`">
                <div class="head">
                  <div class="t">TOP 1</div>
                  <div class="hot">
                    <img src="../assets/homeadd_10.jpg" />
                    {{ $t('re-mai') }}
                  </div>
                </div>
                <div class="pic">
                  <img :src="hotList[0].gheadImages" />
                </div>
                <p>{{ getTypeContent(hotList[0].goodsLanguages).name }}</p>
                <div class="link">
                  <span>{{ $t('cha-kan-xiang-qing') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </div>
              </router-link>
            </li>
            <li v-if="hotList[1]">
              <router-link :to="`/goodInfo/${hotList[1].id}`">
                <div class="head">
                  <div class="t">TOP 2</div>
                  <div class="hot">
                    <img src="../assets/homeadd_10.jpg" />
                    {{ $t('re-mai') }}
                  </div>
                </div>
                <div class="pic">
                  <img :src="hotList[1].gheadImages" />
                </div>
                <p>{{ getTypeContent(hotList[1].goodsLanguages).name }}</p>
                <div class="link">
                  <span>{{ $t('cha-kan-xiang-qing') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </div>
              </router-link>
            </li>
            <li v-if="hotList[2]">
              <router-link :to="`/goodInfo/${hotList[2].id}`">
                <div class="head">
                  <div class="t">TOP 3</div>
                  <div class="hot">
                    <img src="../assets/homeadd_10.jpg" />
                    {{ $t('re-mai') }}
                  </div>
                </div>
                <div class="pic">
                  <img :src="hotList[2].gheadImages" />
                </div>
                <p>{{ getTypeContent(hotList[2].goodsLanguages).name }}</p>
                <div class="link">
                  <span>{{ $t('cha-kan-xiang-qing') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </div>
              </router-link>
            </li>
          </ul>
          <div class="mores">
            <router-link to="/product">
              <span>{{ $t('more-greater-than') }}</span>
              <img src="../assets/Iconsarrow-right.png" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="main_advantage">
        <div class="box">
          <div class="title">
            <p>{{ $t('chan-pin-you-shi') }}</p>
            <!-- <span>1.{{ $t('jing-xuan-yuan-zhuang-ke-li') }}
{{ $t('advanture-intro1') }}
2.{{ $t('ke-ding-zhi-hua-fu-wu') }}
{{ $t('advanture-intro2') }}
3.{{ $t('gong-chang-you-xuan-zhi-chu') }}
{{ $t('gong-chang-yuan-tou') }}
4.{{ $t('zhi-jian-guan-li-xi-tong') }}
{{ $t('yan-ge-de-sheng-chan') }}
5.{{ $t('shou-hou-ji-shu-zhi-chi') }}
{{ $t('suo-you-chan-pin') }}</span> -->
            <!-- <ol>
              <li>
								<p>{{ $t('jing-xuan-yuan-zhuang-ke-li')}}</p>
								<p>{{ $t('advanture-intro1') }}</p>
							</li>
              <li>Tea</li>
              <li>Milk</li>
            </ol> -->
          </div>
          <div class="info">
            <div class="tip">
              <div></div>
              <span>Features</span>
            </div>
          </div>
          <ul>
            <li v-for="(item, index) in featureList" :key="index" @mouseover="showIntro(item, index)">
              <div class="advantage-title">
                <img src="../assets/icon.png" />
                <span>{{ item.title }}</span>
              </div>
              <div v-if="index == currentIndex" class="advantage-introduce">
                {{ item.intro }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="main_case">
        <div class="m">
          <div class="tong_title">
            <p>{{ $t('jie-jue-fang-an') }}</p>
          </div>
          <div class="box">
            <img src="../assets/left.png" @click="prev" class="btn prev" />
            <img src="../assets/right.png" @click="next" class="btn next" />
            <div class="swiper-container" id="swiperIndex1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="label">
                    <a href="javascript:void(0)" @click="handleToPage('case8')">
                      <img src="../assets/solution/教育行业.png" />
                      <div class="sz">
                        <div>
                          <p>{{ $t('zhi-hui-jiao-yu') }}</p>
                          <!-- <span>Automation</span> -->
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="label">
                    <a href="javascript:void(0)" @click="handleToPage('case1')">
                      <img src="../assets/solution/自动化.png" />
                      <div class="sz">
                        <div>
                          <p>{{ $t('zi-dong-hua') }}</p>
                          <!-- <span>Automation</span> -->
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="label">
                    <a href="javascript:void(0)" @click="handleToPage('case5')">
                      <img src="../assets/solution/医疗行业.png" />
                      <div class="sz">
                        <div>
                          <p>{{ $t('yi-liao-hang-ye') }}</p>
                          <!-- <span>Automation</span> -->
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="label">
                    <a href="javascript:void(0)" @click="handleToPage('case2')">
                      <img src="../assets/icon14.png" />
                      <div class="sz">
                        <div>
                          <p>{{ $t('jiao-tong-yun-shu') }}</p>
                          <!-- <span>Automation</span> -->
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="label">
                    <a href="javascript:void(0)" @click="handleToPage('case6')">
                      <img src="../assets/solution/融资.png" />
                      <div class="sz">
                        <div>
                          <p>{{ $t('jin-rong') }}</p>
                          <!-- <span>Automation</span> -->
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="label">
                    <a href="javascript:void(0)" @click="handleToPage('case3')">
                      <img src="../assets/solution/物联网.png" />
                      <div class="sz">
                        <div>
                          <p>{{ $t('wu-lian-wang') }}</p>
                          <!-- <span>Automation</span> -->
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="label">
                    <a href="javascript:void(0)" @click="handleToPage('case7')">
                      <img src="../assets/solution/游戏行业.png" />
                      <div class="sz">
                        <div>
                          <p>{{ $t('you-xi-hang-ye') }}</p>
                          <!-- <span>Automation</span> -->
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="label">
                    <a href="javascript:void(0)" @click="handleToPage('case4')">
                      <img src="../assets/solution/监控.png" />
                      <div class="sz">
                        <div>
                          <p>{{ $t('jian-kong') }}</p>
                          <!-- <span>Automation</span> -->
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mores">
            <router-link to="/cases">
              <span>{{ $t('more-greater-than') }}</span>
              <img src="../assets/Iconsarrow-right.png" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="main_news">
        <div class="m">
          <div class="tong_title">
            <p>{{ $t('xin-wen-zhong-xin') }}</p>
          </div>
          <div class="box">
            <div class="left">
              <router-link :to="`/newsCenter/${newsList[0] && newsList[0].id}`">
                <img :src="newsList[0] && newsList[0].images" />
                <div>
                  <p>{{ newsList[0] && newsList[0].ctime }}</p>
                  <span>{{ getContent(newsList[0] && newsList[0].newsLanguages) }}</span>
                </div>
              </router-link>
            </div>
            <ul>
              <li>
                <router-link :to="`/newsCenter/${newsList[1].id}`" v-if="newsList[1]">
                  <img :src="newsList[1].images" />
                  <div>
                    <p>{{ newsList[1].ctime }}</p>
                    <span>{{ getContent(newsList[1].newsLanguages) }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="`/newsCenter/${newsList[2].id}`" v-if="newsList[2]">
                  <img :src="newsList[2].images" />
                  <div>
                    <p>{{ newsList[2].ctime }}</p>
                    <span>{{ getContent(newsList[2].newsLanguages) }}</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="mores">
            <router-link to="/news">
              <span>{{ $t('more-greater-than') }}</span>
              <img src="../assets/Iconsarrow-right.png" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="main_about_why">
        <div class="m">
          <div class="title">
            <p>{{ $t('wei-shi-mo-xuan-ze-wo-men') }}</p>
            <span>SHENZHEN ETOPSO TECHNOLOGY CO., LTD</span>
          </div>
          <div class="box">
            <div class="item">
              <div class="label">
                <img src="../assets/xcadd2.png" />
                <span>{{ $t('quan-qiu-pin-pai-ying-xiang-li') }}</span>
              </div>
              <div class="label">
                <img src="../assets/xcadd3.png" />
                <span>{{ $t('yan-ke-de-zhi-liang-guan-li-ti-xi') }}</span>
              </div>
            </div>
            <div class="item">
              <div class="label">
                <img src="../assets/xcadd1.png" />
                <span>{{ $t('ding-zhi-hua-jie-jue-fang-an') }}</span>
              </div>
              <div class="label">
                <img src="../assets/xcadd4.png" />
                <span>{{ $t('yuan-liao-cai-gou-qu-dao-feng-fu') }}</span>
              </div>
            </div>
            <div class="item">
              <div class="label">
                <img src="../assets/xcadd.png" />
                <span>{{ $t('sheng-chan-gong-yi-ji-shu-jia-chi') }}</span>
              </div>
              <div class="label">
                <img src="../assets/xcadd5.png" />
                <span>{{ $t('shou-hou-fu-wu-ji-shu-zhi-chi') }}</span>
              </div>
            </div>
          </div>
          <!--<img src="../assets/abouts_03.jpg" />-->
        </div>
      </div>
      <div class="main_about_video">
        <div class="m">
          <div class="video" style="width: 100%">
            <video
              src="https://www.etopso.cn/vedio/etopso.mp4"
              style="width: 100%"
              controls="controls"
              poster="../assets/homeadd_15.jpg"
            ></video>
          </div>
        </div>
      </div>
    </div>
    <icondown @scrolltops="scrolltop"></icondown>
  </div>
</template>

<script>
import { getNewsData } from '@/api/news';
import { getTypeList, getProductList } from '@/api/product';
import { getGoodsList } from '@/api/good';
import 'swiper/css/swiper.css';
import Swiper from 'swiper';
export default {
  name: 'home',
  data() {
    return {
      top: 0,
      flag1: false,
      current: 18,
      swipers: undefined,
      currentIndex: 0,
      newsList: [],
      typeList: [],
      productDataByTab: [],
      hotList: [],
    };
  },
  computed: {
    featureList() {
      //       <!-- <span>1.{{ $t('jing-xuan-yuan-zhuang-ke-li') }}
      // {{ $t('advanture-intro1') }}
      // 2.{{ $t('ke-ding-zhi-hua-fu-wu') }}
      // {{ $t('advanture-intro2') }}
      // 3.{{ $t('gong-chang-you-xuan-zhi-chu') }}
      // {{ $t('gong-chang-yuan-tou') }}
      // 4.{{ $t('zhi-jian-guan-li-xi-tong') }}
      // {{ $t('yan-ge-de-sheng-chan') }}
      // 5.{{ $t('shou-hou-ji-shu-zhi-chi') }}
      // {{ $t('suo-you-chan-pin') }}</span> -->
      //             <!-- <ol>
      //               <li>
      // 								<p>{{ $t('jing-xuan-yuan-zhuang-ke-li')}}</p>
      // 								<p>{{ $t('advanture-intro1') }}</p>
      // 							</li>
      //               <li>Tea</li>
      //               <li>Milk</li>
      //             </ol> -->
      let list = [
        {
          id: 0,
          title: this.$t('jing-xuan-yuan-zhuang-ke-li'),
          intro: this.$t('advanture-intro1'),
        },
        {
          id: 1,
          title: this.$t('ke-ding-zhi-hua-fu-wu'),
          intro: this.$t('advanture-intro2'),
        },
        {
          id: 2,
          title: this.$t('gong-chang-you-xuan-zhi-chu'),
          intro: this.$t('gong-chang-yuan-tou'),
        },
        {
          id: 3,
          title: this.$t('zhi-jian-guan-li-xi-tong'),
          intro: this.$t('yan-ge-de-sheng-chan'),
        },
        {
          id: 4,
          title: this.$t('shou-hou-ji-shu-zhi-chi'),
          intro: this.$t('suo-you-chan-pin'),
        },
      ];
      return list;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    new Swiper('#swiperIndex', {
      autoplay: {
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
    this.swipers = new Swiper('#swiperIndex1', {
      loop: true,
      slidesPerView: 'auto',
      observer: true,
      observeParents: true,
    });
    this.initData();
  },
  methods: {
    scrolltop() {
      document.documentElement.scrollTop = 0;
    },
    initData() {
      this.getNewsList();
      this.getTypeListFn();
      this.getHotData();
    },
    // 新闻数据
    async getNewsList() {
      let params = {
        curPage: 1,
      };
      let res = await getNewsData(params);
      if (res && res.data) {
        this.newsList = res.data?.data?.slice(0, 3);
      } else {
        this.newsList = [];
      }
    },
    // 种类列表
    async getTypeListFn() {
      let params = {
        languageId: this.languageConfigList[localStorage.getItem('langkey')],
      };
      let res = await getTypeList(params);
      if (res.data) {
        this.typeList = res.data.data;
        this.current = res.data.data[0].id;
        this.getTypeListById();
      }
    },
    // 获取种类下面的值
    async getTypeListById() {
      let params = {
        languageId: this.languageConfigList[localStorage.getItem('langkey')],
        type: this.current,
      };
      let res = await getProductList(params);
      if (res.data) {
        this.productDataByTab = res.data.data;
      } else {
        this.productDataByTab = [];
      }
    },
    // 热门商品
    async getHotData() {
      let params = {
        languageId: this.languageConfigList[localStorage.getItem('langkey')],
      };
      let res = await getGoodsList(params);
      if (res.data) {
        this.hotList = res.data.data.slice(0, 3);
      } else {
        this.hotList = [];
      }
    },
    handleToPage(val) {
      this.$router.push({
        path: `/cases?id=${val}`,
      });
    },
    handleClickTab(val) {
      this.current = val;
      this.getTypeListById();
    },
    next() {
      this.swipers.slideNext();
    },
    prev() {
      this.swipers.slidePrev();
    },
    showIntro(item, index) {
      this.currentIndex = index;
    },
    link() {
      //				sessionStorage.setItem('isSelect', 0)
      //				this.$router.push({
      //					path: '/userinfo'
      //				});
    },
    getTypeContent(arr) {
      return arr[0];
    },
    getContent(arr) {
      let obj = arr?.find((ele) => ele.languageId == this.languageConfigList[localStorage.getItem('langkey')]);
      return obj?.title;
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  margin: 10px !important;
}
.advantage-title {
  display: flex;
  align-items: center;
}
.advantage-introduce {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 400;
  color: #ceb1b1;
  line-height: 40px;
}
.swiper-pagination-bullet-active {
  background: #e3dcb2 !important;
}
</style>