import Vue from "vue";
import VueI18n from "vue-i18n";
import cn from "./zh-CN/index.json";
import en from "./en/index.json";
// 俄文
import ewen from './ewen/index.json';
// 日文
import riwen from './riwen/index.json';

// 葡萄牙文
import ptywen from './ptywen/index.json';
Vue.use(VueI18n);
const langList = ["en", "zh", "ewen", "riwen", "ptywen"];

const initKey = initLangKey();


// 初始化语言key
function initLangKey() {
    let langkey = localStorage.getItem("langkey");
    // 如果未初始化，通过浏览器判断应该设置成啥语言
    if (!langkey) {
        const lang = "en";
        switch (lang) {
            case "en":
                langkey = "en";
                break;
            case "zh":
                langkey = "zh";
                break;
            case "ewen":
                langkey = "ewen"
                break;
            case "riwen":
                langkey = "riwen"
                break;
            case "ptywen":
                langkey = "ptywen"
                break;
            default:
                langkey = "en";
                break;
        }
    } else if (!langList.includes(langkey)) {
        // 如果不是en,zh,默认en
        langkey = "en";
    }
    localStorage.setItem("langkey", langkey);
    return langkey;
}

const i18n = new VueI18n({
    locale: initKey,
    messages: {
        en: Object.assign({}, en),
        zh: Object.assign({}, cn),
        ewen: Object.assign({}, ewen),
        riwen: Object.assign({}, riwen),
        ptywen: Object.assign({}, ptywen),
    },
});

export default i18n;