import Vue from 'vue'
import Router from 'vue-router'
import home from '@/components/home'
import about from '@/components/about'
import brand from '@/components/brand'
import cases from '@/components/case'
import data_down from '@/components/data_down'
import news from '@/components/news'
import odm from '@/components/odm'
import goodInfo from '@/components/goodInfo'
import product_list from '@/components/product_list'
import product from '@/components/product'
import service from '@/components/service'
import support from '@/components/support'
import companyCulture from '@/components/companyCulture'
import newsCenter from '@/components/newsCenter'
import zhengshu from '@/components/zhengshu'
import concatUs from '@/components/concatUs'

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			name: 'home',
			component: home
		},
		{
			path: '/support',
			name: 'support',
			component: support
		},
		{
			path: '/service',
			name: 'service',
			component: service
		},
		{
			path: '/product',
			name: 'product',
			component: product
		},
		{
			path: '/productList',
			name: 'product_list',
			component: product_list
		},
		{
			path: '/productList/:productID',
			name: 'product_list',
			component: product_list
		},
		{
			path: '/goodInfo/:goodID',
			name: 'productInfo',
			component: goodInfo
		},
		{
			path: '/odm',
			name: 'odm',
			component: odm
		},
		{
			path: '/news',
			name: 'news',
			component: news
		},
		{
			path: '/data_down',
			name: 'data_down',
			component: data_down
		},
		{
			path: '/cases',
			name: 'cases',
			component: cases
		},
		{
			path: '/brand',
			name: 'brand',
			component: brand
		},
		{
			path: '/about',
			name: 'about',
			component: about
		},
		// 公司文化
		{
			path: '/companyCulture',
			name: 'companyCulture',
			component: companyCulture
		},
		// 新闻中心
		{
			path: '/newsCenter/:newsId',
			name: 'newsCenter',
			component: newsCenter
		},
		// 资质证书
		{
			path: '/zs',
			name: 'zhengshu',
			component: zhengshu
		},
		// 联系我们
		{
			path: '/concatUs',
			name: 'concatUs',
			component: concatUs
		},
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
})