<template>
	<div class="contents zhengshu-contents">
		<div class="content">
			<div class="banners" :style="'height: 750px;padding: 0;background-image: url(' + require('../assets/about.jpg') +')'">
				<h4>{{ $t('header.qualificationCertificate') }}</h4>
			</div>
			<div class="main_case">
				<div class="m">
					<div class="tong_title">
						<p>{{ $t('header.qualificationCertificate') }}</p>
					</div>
					<div class="box">
						<img src="../assets/left1.png" style="backgroundColor:#000"  @click="prev" class="btn prev" />
						<img src="../assets/right1.png" style="backgroundColor:#000"  @click="next" class="btn next" />

						<div class="swiper-container" id="swiperIndex1">
							<div class="swiper-wrapper">
								<div class="swiper-slide">
									<div class="label">
										<a href="javascript:;">
											<img src="../assets/zs1.png" />
										</a>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="label">
										<a href="javascript:;">
											<img src="../assets/zs2.png" />
										</a>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="label">
										<a href="javascript:;">
											<img src="../assets/zs3.png" />
										</a>
									</div>
								</div>
								<div class="swiper-slide">
									<div class="label">
										<a href="javascript:;">
											<img src="../assets/zs4.png" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<icondown @scrolltops='scrolltop'></icondown>
	</div>
</template>

<script>
	import "swiper/css/swiper.css";
	import Swiper from "swiper";
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false,
				swipers: undefined,
				swipers1: undefined
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
			this.swipers = new Swiper("#swiperIndex1", {
				loop: true,
				slidesPerView: "auto",
				spaceBetween: '2%',
				slidesPerGroup: 3,
			});
		},
		methods: {
			next() {
				this.swipers.slideNext()
			},
			prev() {
				this.swipers.slidePrev()
			},
			scrolltop() {
				document.documentElement.scrollTop = 0
			},
			link() {

			},
			handleScroll() {
				if((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
					this.flag = true
				} else {
					this.flag = false
				}
			}
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style lang="less" scoped>
.zhengshu-contents{
	.swiper-container{
		box-sizing: border-box;
		width: 100%; 
		.swiper-slide {
			width: 32%;
		}
	}
}
	.swiper-pagination-bullet-active {
		background: #E3DCB2 !important;
	}
</style>