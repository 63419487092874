//rem
(function () {
  let orientation = window.matchMedia("(orientation: portrait)");
  function onMatchMeidaChange() {
    setTimeout(() => {
      // 重新计算竖屏宽度rem
      autoRootFontSize()
    });
  }
  onMatchMeidaChange();
  orientation.addListener(onMatchMeidaChange);

  /* 计算rem */
  function autoRootFontSize() {
    const baseSize = 16;
    let setSize = Math.max(document.documentElement.getBoundingClientRect().width,1100) / 1920 * baseSize;
    document.documentElement.style.fontSize = (setSize > baseSize ? baseSize : setSize) + 'px';
    if (document.documentElement.clientWidth <= 1100) {
      document.getElementsByTagName('html')[0].style.fontSize = document.documentElement.clientWidth / 7.5 + 'px';
    }
  }
  window.addEventListener('resize', autoRootFontSize);
  autoRootFontSize();
})();