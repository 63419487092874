<template>
	<div class="contents news-content">
		<div class="content">
			<div class="banners" :style="'background-image: url(' + require('../assets/newsbg.png') +')'">
				<h4>{{ $t('xin-wen-zhong-xin') }}</h4>
			</div>
			<div class="main_news_box">
				<div class="m">
					<div class="tabbar">
						<div :class="tabValue == '1' ? 'on' : ''" @click="handleClickTab('1')">{{ $t('qiyedongtai') }}</div>
						<div :class="tabValue == '2' ? 'on' : ''" @click="handleClickTab('2')">{{ $t('hangyedongtai') }}</div>
					</div>
					<div class="box">
						<div class="news" v-if="newsList.length > 0">
							<router-link :to="`/newsCenter/${newsList[0].id}`">
								<div class="pic">
									<img :src="newsList[0].images" />
								</div>
								<div class="grow">
									<p class="title">{{ getContent(newsList[0].newsLanguages).title }}</p>
									<div class="content-news" v-html="getContent(newsList[0].newsLanguages).content"></div>
									<span>{{ newsList[0].ctime }}</span>
								</div>
							</router-link>
						</div>
						<ul v-if="newsList.length > 1">
							<li v-for="(item, index) in newsList.slice(1)" :key="index">
								<router-link :to="`/newsCenter/${item.id}`">
									<div class="pic">
										<img :src="item.images" />
									</div>
									<div class="info">
										<p>{{ getContent(item.newsLanguages).title }}</p>
										<span>{{ getContent(item.newsLanguages).content }}</span>
										<em>{{ item.ctime }}</em>
									</div>
								</router-link>
							</li>
						</ul>
						<!-- <div class="pagelist">
							<a href="#">←</a>
							<a href="#" class="on">1</a>
							<a href="#">2</a>
							<a href="#">→</a>
						</div> -->
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination
					background
					layout="prev, pager, next"
					@current-change="handleCurrentChange"
					hide-on-single-page
					:total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getNewsData } from '@/api/news'
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false,
				tabValue: '1',
				list: [
					{
						label: 'zh',
						val: 1
					},
					{
						label: 'en',
						val: 2
					},
					{
						label: 'ewen',
						val: 3
					},
					{
						label: 'riwen',
						val: 4
					},
					{
						label: 'ptywen',
						val: 5
					}
				],
				newsList: [],
				curPage: 1,
				total: 0,
			}
		},
		mounted() {
			this.getNews()
		},
		methods: {
			handleClickTab(val) {
				this.tabValue = val;
				this.getNews()
			},
			// 切换
			handleCurrentChange(val) {
				this.curPage = val
				this.getNews()
			},
			getLanguage(str) {
				return this.list.find(ele => ele.label == str).val || '1'
			},
			getContent(data) {
				return data.find(ele => ele.languageId == this.getLanguage(localStorage.getItem('langkey')))
			},
			async getNews() {
				let params = {
					curPage: this.curPage,
					languageId: this.getLanguage(localStorage.getItem('langkey')),
					type: this.tabValue
				}
				let res = await getNewsData(params)
				console.log('rrrrr', res)
				if (res && res.data) {
					this.total = res.data.totalRows
					this.curPage = res.data.curPage
					this.newsList = res.data.data
				} else {
					this.total = 0
					this.curPage = 1
					this.newsList = []
				}
			},
			toDetail(id) {
				this.$router.push(`/newsCenter/${id}`);
			},
		},
	}
</script>

<style lang="less" scoped>
	.swiper-pagination-bullet-active {
		background: #E3DCB2 !important;
	}
	.news-content {
		background-color: #111827;
		.main_news_box {
			.title {
				font-size: 24px;
				font-weight: bold;
				margin-bottom: 20px;
			}
			.content-news {
				display: -webkit-box;
				overflow: hidden;
				white-space: normal !important;
				text-overflow: ellipsis;
				word-wrap:break-word;
				-webkit-line-clamp:3;
				-webkit-box-orient: vertical;
				margin-bottom: 20px;
				p {
					font-size: 14px;
					line-height: 1.2;
				}
			}
		}
		.pagination {
			width: 50%;
			margin: 0 auto;
			background-color: #111827;
			padding: 0 0 64px 0;
			.el-pagination {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
</style>