import request from '@/api/request'
export function getTypeList(params) {
  return request({
    url: '/client/getProducts',
    method: 'get',
    params
  })
}
// 获取产品列表
export function getProductList(data) {
  return request({
    url: '/client/getProductTypeProList',
    method: 'get',
    params: data
  })
}

// 获取产品列表(弹窗)
export function getProductListByDialog(data) {
  return request({
    url: '/client/getProductTypeByCpm',
    method: 'get',
    params: data
  })
}

export function getProductById(data) {
  return request({
    url: '/client/getProductById',
    method: 'get',
    params: data
  })
}

export function getGoodById(data) {
  return request({
    url: '/client/getGoodById',
    method: 'get',
    params: data
  })
}
