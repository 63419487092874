<template>
	<div class="contents">
		<div class="content">
			<div class="banners"  :style="'height: 750px;padding: 0;background-image: url(' + require('../assets/banner2.jpg') +')'">
			</div>
			<div class="main_Join">
				<div class="m">
					<h3>{{ $t('jia-meng-you-shi') }}</h3>
					<ul>
						<li>
							<div class="grow">
								<p><span>01</span>{{ $t('chan-pin-you-shi') }}</p>
								<em>{{ $t('pp-intro1') }}</em>
							</div>
							<div class="pic">
								<img src="../assets/brands_03.jpg"/>
							</div>
						</li>
						<li>
							<div class="grow">
								<p><span>02</span>{{ $t('sheng-chan-you-shi') }}</p>
								<em>{{ $t('pp-intro2') }}</em>
							</div>
							<div class="pic">
								<img src="../assets/brands_07.jpg"/>
							</div>
						</li>
						<li>
							<div class="grow">
								<p><span>03</span>{{ $t('gong-ying-you-shi') }}</p>
								<em>{{ $t('pp-intro3') }}</em>
							</div>
							<div class="pic">
								<img src="../assets/brands_11.jpg"/>
							</div>
						</li>
						<li>
							<div class="grow">
								<p><span>04</span>{{ $t('pin-pai-you-shi') }}</p>
								<em>{{ $t('pp-intro4') }}</em>
							</div>
							<div class="pic">
								<img src="../assets/brands_14.jpg"/>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="main_agent">
				<div class="m">
					<h3>{{ $t('dai-li-zhi-chi') }}</h3>
					<ul>
						<li>
							<em>01</em>
							<img src="../assets/brand4.png"/>
							<p>{{ $t('xuan-zhi-zhi-chi') }}</p>
							<span>{{ $t('dlzc-intro1') }}</span>
						</li>
						<li>
							<em>02</em>
							<img src="../assets/brand1.png"/>
							<p>{{ $t('guan-li-zhi-chi') }}</p>
							<span>{{ $t('dlzc-intro2') }}</span>
						</li>
						<li>
							<em>03</em>
							<img src="../assets/brand8.png"/>
							<p>{{ $t('pin-pai-xing-xiang-zhi-chi') }}</p>
							<span>{{ $t('dlzc-intro3') }}</span>
						</li>
						<li>
							<em>04</em>
							<img src="../assets/brand13.png"/>
							<p>{{ $t('tui-guang-zhi-chi') }}</p>
							<span>{{ $t('dlzc-intro4') }}</span>
						</li>
						<li>
							<em>05</em>
							<img src="../assets/brand11.png"/>
							<p>{{ $t('shou-hou-zhi-chi') }}</p>
							<span>{{ $t('dlzc-intro5') }}</span>
						</li>
						<li>
							<em>06</em>
							<img src="../assets/brand14.png"/>
							<p>{{ $t('xiao-shou-zhi-chi') }}</p>
							<span>{{ $t('dlzc-intro6') }}</span>
						</li>
					</ul>
				</div>
			</div>
			<div class="main_processes">
				<div class="m">
					<h3>{{ $t('jia-meng-liu-cheng') }}</h3>
					<ul>
						<li>
							<img src="../assets/brand3.jpg"/>
							<span>{{ $t('he-zuo-shen-qing') }}</span>
						</li>
						<li>
							<img src="../assets/brand6.png"/>
							<span>{{ $t('yi-xiang-jiao-liu') }}</span>
						</li>
						<li>
							<img src="../assets/brand10.png"/>
							<span>{{ $t('shen-he-jie-guo') }}</span>
						</li>
						<li>
							<img src="../assets/brand12.png"/>
							<span>{{ $t('tou-zi-ji-hua-shu') }}</span>
						</li>
						<li>
							<img src="../assets/brand7.png"/>
							<span>{{ $t('zi-zhi-kao-cha') }}</span>
						</li>
						<li>
							<img src="../assets/brand9.png"/>
							<span>{{ $t('que-ding-he-zuo') }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<icondown @scrolltops='scrolltop'></icondown>
	</div>
</template>

<script>
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			scrolltop() {
					document.documentElement.scrollTop = 0
			},
			link() {
				
			},
			handleScroll() {
				if((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
					this.flag = true
				} else {
					this.flag = false
				}
			}
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style>
	.swiper-pagination-bullet-active {
		background: #E3DCB2 !important;
	}
</style>