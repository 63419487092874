<template>
	<div class="contents">
		<div class="content">
			<div class="banners" :style="'background-image: url(' + require('../assets/faqbanner.jpg') +')'">
				<h4>{{ $t('ji-shu-zhi-chi') }}</h4>
				<div class="tabbar">
					<div class="m">
						<div>
							<router-link to='/service'>
								<img src="../assets/download1.png" />
								<span>{{ $t('shou-hou-fu-wu') }}</span>
							</router-link>
						</div>
						<div>
							<router-link to='/data_down'>
								<img src="../assets/download.png" />
								<span>{{ $t('zi-liao-xia-zai') }}</span>
							</router-link>
						</div>
						<div class="on">
							<router-link to='/support'>
								<img src="../assets/download2.png" />
								<span>FAQ</span>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="main_support">
				<ul class="m">
					<li><a href="#">
						<div class="t">
							<img src="../assets/Frame.png"/>
							<span>{{ $t('wo-men-ru-he-zhi-dao-que-qie-de-rong-liang') }}</span>
						</div>
						<p>{{ $t('i1') }}</p>
					</a></li>
					<li><a href="#">
						<div class="t">
							<img src="../assets/Frame.png"/>
							<span>{{ $t('i2') }}</span>
						</div>
						<p>{{ $t('i3') }}</p>
					</a></li>
					<li><a href="#">
						<div class="t">
							<img src="../assets/Frame.png"/>
							<span>{{ $t('hdd-he-ssd-you-shi-mo-qu-bie') }}</span>
						</div>
						<p>{{ $t('i4') }}</p>
					</a></li>
					<li><a href="#">
						<div class="t">
							<img src="../assets/Frame.png"/>
							<span>{{ $t('i5') }}</span>
						</div>
					</a></li>
					<li><a href="#">
						<div class="t">
							<img src="../assets/Frame.png"/>
							<span>{{ $t('i6') }}</span>
						</div>
					</a></li>
				</ul>
			</div>
		</div>
		<icondown @scrolltops='scrolltop'></icondown>
	</div>
</template>

<script>
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			scrolltop() {
				document.documentElement.scrollTop = 0
			},
			link() {

			},
			handleScroll() {
				if((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
					this.flag = true
				} else {
					this.flag = false
				}
			}
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style>
	.swiper-pagination-bullet-active {
		background: #E3DCB2 !important;
	}
</style>