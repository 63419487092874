<template>
  <div class="contents">
    <div class="content">
      <div class="banners" :style="'background-image: url(' + require('../assets/case.jpg') + ')'"></div>
      <div class="main_product_list">
        <ul class="m">
          <li v-for="item in productList" :key="item.id">
            <router-link :to="`/productList/${item.productLanguages[0].proId}`">
              <div class="pic">
                <img :src="item.productImages" />
              </div>
              <div class="grow">
                <p>{{ item.productLanguages[0].name }}</p>
                <span>{{ item.productLanguages[0].productDesc }}</span>
                <div @click.prevent="toDetail(item.productLanguages[0].proId)">{{ $t('cha-kan-xiang-qing') }}</div>
              </div>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/product_list">
              <div class="pic">
                <img src="../assets/product/m2nvme.jpg" />
              </div>
              <div class="grow">
                <p>M.2 PCIe NVMe SSD</p>
                <span>{{ $t('nvme') }}</span>
                <div @click.prevent="toDetail">{{ $t('cha-kan-xiang-qing') }}</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/product_list">
              <div class="pic">
                <img src="../assets/product/m2sata.jpg" />
              </div>
              <div class="grow">
                <p>M.2 SATA SSD</p>
                <span>{{ $t('m2sata') }}</span>
                <div @click.prevent="toDetail">{{ $t('cha-kan-xiang-qing') }}</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/product_list">
              <div class="pic">
                <img src="../assets/product/ddr4.jpg" />
              </div>
              <div class="grow">
                <p>ARGB DDR4</p>
                <span>{{ $t('argb') }}</span>
                <div @click.prevent="toDetail">{{ $t('cha-kan-xiang-qing') }}</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/product_list">
              <div class="pic">
                <img src="../assets/product/ddr.jpg" />
              </div>
              <div class="grow">
                <p>DDR RAM</p>
                <span>{{ $t('ddrram1') }}</span>
                <span>{{ $t('ddrram2') }}</span>
                <div>{{ $t('cha-kan-xiang-qing') }}</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/product_list">
              <div class="pic">
                <img src="../assets/product/usb.jpg" />
              </div>
              <div class="grow">
                <p>USB Flash Drive</p>
                <span>{{ $t('usb') }}</span>
                <div @click.prevent="toDetail">{{ $t('cha-kan-xiang-qing') }}</div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/product_list">
              <div class="pic">
                <img src="../assets/product/msatassd.jpg" />
              </div>
              <div class="grow">
                <p>mSATA SSD</p>
                <span>{{ $t('msatassd') }}</span>
                <div @click.prevent="toDetail">{{ $t('cha-kan-xiang-qing') }}</div>
              </div>
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
    <icondown @scrolltops="scrolltop"></icondown>
  </div>
</template>

<script>
import { getProductList } from '@/api/product';
export default {
  name: 'home',
  data() {
    return {
      top: 0,
      flag: false,
      flag1: false,
      headerflag: false,
      productList: [],
    };
  },
  created() {
    this.getProductList();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrolltop() {
      document.documentElement.scrollTop = 0;
    },
    link() {},
    handleScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    toDetail(id) {
      this.$router.push(`/productList/${id}`);
    },
    async getProductList() {
      try {
        let params = {
          curPage: 1,
          numPerPage: 100,
          // languageId: localStorage.getItem('languageID') || 1,
          languageId: this.languageConfigList[localStorage.getItem('langkey')],
          type: '',
        };
        const res = await getProductList(params);
        console.log({ res });
        this.productList = res.data?.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
.swiper-pagination-bullet-active {
  background: #e3dcb2 !important;
}
</style>