<template>
  <div class="contents">
    <div class="content">
      <div
        class="banners"
        :style="'height: 750px;padding: 0;background-image: url(' + require('../assets/banner6.jpg') + ')'"
      ></div>
      <div class="main_case_list">
        <div class="label" id="case1" :style="'background-image: url(' + require('../assets/case4.jpg') + ')'">
          <div class="m">
            <div class="box">
              <p>Automation</p>
              <span>{{ $t('zi-dong-hua') }}</span>
              <em>{{ $t('zdh-intro1') }}</em>
              <em>{{ $t('zdh-intro2') }}</em>
              <ul>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}3</span>
                  </a>
                </li>
              </ul>
              <div class="more">
                <a href="#">
                  <span>{{ $t('geng-duo-chan-pin') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="label" id="case2" :style="'background-image: url(' + require('../assets/case2.jpg') + ')'">
          <div class="m">
            <div class="box">
              <p>Smart Transportation</p>
              <span>{{ $t('zhi-neng-jiao-tong') }}</span>
              <em>{{ $t('znjt-intro') }}</em>
              <ul>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}3</span>
                  </a>
                </li>
              </ul>
              <div class="more">
                <a href="#">
                  <span>{{ $t('geng-duo-chan-pin') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="label" id="case3" :style="'background-image: url(' + require('../assets/case3.jpg') + ')'">
          <div class="m">
            <div class="box">
              <p>AIoT</p>
              <span>{{ $t('ren-gong-zhi-neng-wu-lian-wang') }}</span>
              <em>
                {{ $t('rgznwlw-intro') }}
              </em>
              <ul>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}3</span>
                  </a>
                </li>
              </ul>
              <div class="more">
                <a href="#">
                  <span>{{ $t('geng-duo-chan-pin') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="label" id="case4" :style="'background-image: url(' + require('../assets/case5.jpg') + ')'">
          <div class="m">
            <div class="box">
              <p>Surveillance</p>
              <span>{{ $t('jian-kong') }}</span>
              <em>{{ $t('jk-intro') }}</em>
              <ul>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}3</span>
                  </a>
                </li>
              </ul>
              <div class="more">
                <a href="#">
                  <span>{{ $t('geng-duo-chan-pin') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="label" id="case5" :style="'background-image: url(' + require('../assets/case1.jpg') + ')'">
          <div class="m">
            <div class="box">
              <p>Medical</p>
              <span>{{ $t('yi-liao-hang-ye') }}</span>
              <em>{{ $t('ylhy-intro') }}</em>
              <ul>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}3</span>
                  </a>
                </li>
              </ul>
              <div class="more">
                <a href="#">
                  <span>{{ $t('geng-duo-chan-pin') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="label" id="case6" :style="'background-image: url(' + require('../assets/casess1.jpg') + ')'">
          <div class="m">
            <div class="box">
              <p>Internet Finance</p>
              <span>{{ $t('hu-lian-wang-jin-rong') }}</span>
              <em>
                {{ $t('hlwjr-intro') }}
              </em>
              <ul>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}3</span>
                  </a>
                </li>
              </ul>
              <div class="more">
                <a href="#">
                  <span>{{ $t('geng-duo-chan-pin') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="label" id="case7" :style="'background-image: url(' + require('../assets/casess.jpg') + ')'">
          <div class="m">
            <div class="box">
              <p>Vedio games</p>
              <span>{{ $t('dian-zi-you-xi') }}</span>
              <em>
                {{ $t('dzyx-intro') }}
              </em>
              <ul>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}3</span>
                  </a>
                </li>
              </ul>
              <div class="more">
                <a href="#">
                  <span>{{ $t('geng-duo-chan-pin') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="label" id="case8" :style="'background-image: url(' + require('../assets/banner6.jpg') + ')'">
          <div class="m">
            <div class="box">
              <p>Smart Education</p>
              <span>{{ $t('zhi-hui-jiao-yu') }}</span>
              <em>{{ $t('zhjy-intro') }}</em>
              <ul>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}1</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}2</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div></div>
                    <span>{{ $t('gu-tai-ying-pan') }} {{ $t('model') }}3</span>
                  </a>
                </li>
              </ul>
              <div class="more">
                <a href="#">
                  <span>{{ $t('geng-duo-chan-pin') }}</span>
                  <img src="../assets/Iconsarrow-right.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <icondown @scrolltops="scrolltop"></icondown>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      top: 0,
      flag: false,
      flag1: false,
      headerflag: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    console.log('llll', this.$route.query.id)
    if (this.$route.query.id) {
      setTimeout(() => {
        document.getElementById(this.$route.query.id).scrollIntoView();
      }, 80);
    }
  },
  watch:{
    // $route(to, from){
    //   console.log('poiuygfghj:', to, from)
    //   // this.activityPath = to.path
    //   if (to.path == '/cases') {
    //     console.log('执行了：', document)
    //     document.getElementById(to.query.id).scrollIntoView();
    //   }
    // }
  },
  methods: {
    scrolltop() {
      document.documentElement.scrollTop = 0;
    },
    link() {},
    handleScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
.swiper-pagination-bullet-active {
  background: #e3dcb2 !important;
}
</style>