<template>
	<div>
		<div class="show_btns">
			<ul>
				<li>
					<p>Tel: +86 13510354835</p>
					<img src="../assets/xcadd10.png"/>
				</li>
				<li>
					<p>Mail: manager@etopso-cn.com</p>
					<img src="../assets/xcadd9.png"/>
				</li>
				<li>
					<p>whats App：+86 13510354835</p>
					<img src="../assets/xcadd8.png"/>
				</li>
				<li>
					<p>wechat: +86 13510354835</p>
					<img src="../assets/xcadd7.png"/>
				</li>
			</ul>
		</div>
			<div class="down_btn">
				<div class="ec">
					<img src="../assets/icon15.png" @click="show = true" class="ss" />
					<div class="message" v-if="show">
						<div class="head">
							<p>{{ $t('liu-yan-ban') }}</p>
							<img src="../assets/carbo.png" @click="show = false"/>
						</div>
						<div class="form">
							<p>{{ $t('ru-guo-nin-dui-wo-men-de-chan-pin-gan-xing-qu-bing-xiang-le-jie-geng-duo-xiang-qing-qing-zai-zhe-li-liu-yan-wo-men-jiang-jin-kuai-hui-fu-nin') }}</p>
							<textarea name="" :placeholder="$t('h1')" v-model="content" rows="" cols=""></textarea>
							<div class="label">
								<img src="../assets/xcadd13.png"/>
								<input type="text" :placeholder="`${$t('you-xiang')}(${$t('bi-tian')})`" name="" id="" v-model="email" />
							</div>
							<div class="label">
								<img src="../assets/xcadd12.png"/>
								<input type="text" :placeholder="$t('dian-hua')" name="" id="" v-model="phone" />
							</div>
							<div class="send" @click="handleSend">{{ $t('fa-song') }}</div>
						</div>
					</div>
				</div>
				<img src="../assets/top.png" @click="scrolltop" class="top" />
			</div>
	</div>
</template>

<script>
import { addFeedback } from '@/api/feedback.js'
	export default {
		name: 'icondown',
		data() {
			return {
				show: false,
				content: "",
				email: "",
				phone: "",
			}
		},
		methods: {
			scrolltop(){
				this.$emit('scrolltops');
			},
			async handleSend() {
				let params = {
					content: this.content,
					email: this.email,
					phone: this.phone,
				}
				let res = await addFeedback(params)
				console.log('dddd', res)
				if (res.code == 200) {
					this.$message('提交成功')
				}
				this.show = false
			},
		},
	}
</script>

<style scoped>

</style>