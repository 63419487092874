<template>
	<div class="contents">
		<div class="content">
			<div class="banners" :style="'height: 750px;padding: 0;background-image: url(' + require('../assets/about.jpg') +')'">
				<h4>{{ $t('gong-si-jian-jie') }}</h4>
			</div>
			<div class="main_about_why">
				<div class="m">
					<div class="title">
						<p>{{ $t('wei-shi-mo-xuan-ze-wo-men') }}</p>
						<span>SHENZHEN ETOPSO TECHNOLOGY CO., LTD</span>
					</div>
					<div class="box">
						<div class="item">
							<div class="label">
								<img src="../assets/xcadd2.png" />
								<span>{{ $t('quan-qiu-pin-pai-ying-xiang-li') }}</span>
							</div>
							<div class="label">
								<img src="../assets/xcadd3.png" />
								<span>{{ $t('yan-ke-de-zhi-liang-guan-li-ti-xi') }}</span>
							</div>
						</div>
						<div class="item">
							<div class="label">
								<img src="../assets/xcadd1.png" />
								<span>{{ $t('ding-zhi-hua-jie-jue-fang-an') }}</span>
							</div>
							<div class="label">
								<img src="../assets/xcadd4.png" />
								<span>{{ $t('yuan-liao-cai-gou-qu-dao-feng-fu') }}</span>
							</div>
						</div>
						<div class="item">
							<div class="label">
								<img src="../assets/xcadd.png" />
								<span>{{ $t('sheng-chan-gong-yi-ji-shu-jia-chi') }}</span>
							</div>
							<div class="label">
								<img src="../assets/xcadd5.png" />
								<span>{{ $t('shou-hou-fu-wu-ji-shu-zhi-chi') }}</span>
							</div>
						</div>
					</div>
					<!--<img src="../assets/abouts_03.jpg" />-->
				</div>
			</div>
			<div class="main_about_video">
				<div class="m">
					<div class="video" style="width: 100%;">
						<video src="https://www.etopso.cn/vedio/etopso.mp4" style="width: 100%;" controls="controls" poster="../assets/homeadd_15.jpg">
						</video>
					</div>
				</div>
			</div>
			<div class="main_history">
				<h3>{{ $t('fa-zhan-li-cheng') }}</h3>
				<ul>
					<li>
						<div class="year">2016</div>
						<div class="grow">
							<div class="head">
								<p>{{ $t('gong-si-zheng-shi-cheng-li') }}</p>
							</div>
							<div class="label">
								<a href="#">
									<div class="font">
										<p>{{ $t('ii1') }}</p>
									</div>
									<div class="pic"><img src="../assets/fazhan1.jpg" /></div>
								</a>
							</div>
						</div>
					</li>
					<li>
						<div class="year">2017</div>
						<div class="grow">
							<div class="head">
								<p>{{ $t('gong-chang-sheng-chan-yan-fa-she-bei-quan-mian-wan-shan') }}</p>
							</div>
							<div class="label">
								<a href="#">
									<div class="font">
										<p>{{ $t('ii2') }}</p>
									</div>
									<div class="pic"><img src="../assets/fazhan2.jpg" /></div>
								</a>
							</div>
						</div>
					</li>
					<li>
						<div class="year">2018</div>
						<div class="grow">
							<div class="head">
								<p>{{ $t('cheng-wei-gu-tai-ying-pan-hang-ye-top10') }}</p>
							</div>
							<div class="label">
								<a href="#">
									<div class="font">
										<p>{{ $t('ii3') }}</p>
									</div>
									<div class="pic"><img src="../assets/fazhan3.jpg" /></div>
								</a>
							</div>
						</div>
					</li>
					<li>
						<div class="year" style="font-size: 22px;">2019-2020</div>
						<div class="grow">
							<div class="head">
								<p>{{ $t('gong-chang-quan-mian-kuo-da') }}</p>
							</div>
							<div class="label">
								<a href="#">
									<div class="font">
										<p>{{ $t('ii4') }}</p>
									</div>
									<div class="pic"><img src="../assets/fazhan4.jpg" /></div>
								</a>
							</div>
						</div>
					</li>
					<li>
						<div class="year">2021</div>
						<div class="grow">
							<div class="head">
								<p>{{ $t('cheng-li-du-li-wai-mao-yun-ying-zhong-xin') }}</p>
							</div>
							<div class="label">
								<a href="#">
									<div class="font">
										<p>{{ $t('ii5') }}</p>
									</div>
									<div class="pic"><img src="../assets/fazhan5.jpg" /></div>
								</a>
							</div>
						</div>
					</li>
					<li>
						<div class="year">2022</div>
						<div class="grow">
							<div class="head">
								<p>{{ $t('cheng-li-fen-gong-si') }}</p>
							</div>
							<div class="label">
								<a href="#">
									<div class="font">
										<p>{{ $t('ii6') }}</p>
									</div>
									<div class="pic"><img src="../assets/fazhan6.jpg" /></div>
								</a>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="main_factory">
				<div class="m">
					<h3>{{ $t('gong-si-gong-chang') }}</h3>
					<ul>
						<li>
							<a href="#"><img src="../assets/about4.png" /></a>
						</li>
						<li>
							<a href="#"><img src="../assets/about2.png" /></a>
						</li>
						<li>
							<a href="#"><img src="../assets/about6.png" /></a>
						</li>
						<li>
							<a href="#"><img src="../assets/about1.png" /></a>
						</li>
						<li>
							<a href="#"><img src="../assets/about5.png" /></a>
						</li>
						<li>
							<a href="#"><img src="../assets/about3.png" /></a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<icondown @scrolltops='scrolltop'></icondown>
	</div>
</template>

<script>
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			scrolltop() {
				document.documentElement.scrollTop = 0
			},
			link() {

			},
			handleScroll() {
				if((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
					this.flag = true
				} else {
					this.flag = false
				}
			}
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style>
	.swiper-pagination-bullet-active {
		background: #E3DCB2 !important;
	}
</style>