<template>
  <div id="app">
    <headers></headers>
    <router-view></router-view>
    <footers></footers>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      current: 0,
    };
  },
  created() {},
  mounted() {
    this.current = sessionStorage.getItem('isSelect');
  },
};
</script>

<style>
</style>