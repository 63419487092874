<template>
  <div class="contents custom-detail">
    <div class="content">
      <div class="main_prodcut_info">
        <div class="m">
          <div class="menus">
            <router-link to="/">{{ $t('zhu-ye') }}</router-link>
            <span>/</span>
            <router-link to="/product">{{ $t('chan-pin-zhong-xin') }}</router-link>
            <span>/</span>
            <a href="#">{{ goodInfo.name }}</a>
          </div>
          <div class="prodcut_box">
            <div class="slide">
              <div class="box">
                <img src="../assets/infos_09.jpg" @click="prev" class="btn prev" />
                <img src="../assets/infos_06.jpg" @click="next" class="btn next" />
                <div class="swiper-container" id="swiperIndex">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide swiper-no-swiping" v-for="(item, index) in thumList" :key="index">
                      <a href="#">
                        <img :src="item" />
                      </a>
                    </div>
                  </div>
                  <div class="swiper-pagination"></div>
                </div>
              </div>
              <p>{{ current + 1 }}/{{ thumList.length }}</p>
              <div class="list">
                <div class="swiper-container" id="swiperIndex1">
                  <div class="swiper-wrapper">
                    <div
                      class="swiper-slide"
                      @click="changes(index)"
                      :class="current == index ? 'on' : ''"
                      v-for="(item, index) in thumList"
                      :key="index"
                    >
                      <div class="lab"><img :src="item" /></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grow">
              <h3>{{ goodInfo.name }}</h3>
              <!-- <p>{{ $t('25-inch-sataIII') }}</p> -->
              <ul>
                <li>
                  <p>
                    {{ $t('shang-pin-te-zheng') }}:
                    <span v-for="subItem in featureList" :key="subItem">
                      {{ subItem }}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    {{ $t('shang-pin-rong-liang') }}:
                    <span v-for="subItem in volumeList" :key="subItem">
                      {{ subItem }}
                    </span>
                  </p>
                </li>
              </ul>
              <div class="button">
                <router-link to="/concatUs">{{ $t('liji-zixun') }}</router-link>
              </div>
            </div>
          </div>
          <div class="main_info_box">
            <div class="left">
              <div class="product_center">
                <h3>{{ $t('chan-pin-zhong-xin') }}</h3>
                <ul class="pc">
                  <li
                    v-for="(item, index) in goodsList"
                    :key="index"
                    @click="setGoodItem(item, index)"
                    :class="currentGoods == index ? 'isActive' : ''"
                  >
                    <div class="line">
                      <p>{{ item.goodsLanguages[0].name }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="grow">
              <div class="head">{{ $t('chan-pin-xiang-qing') }}</div>
              <div class="item">
                <!-- <div class="video-detail">
                  <video
                    src="https://www.etopso.cn/vedio/etopso.mp4"
                    style="width: 100%"
                    controls="controls"
                    poster="../assets/homeadd_15.jpg"
                  ></video>
                </div> -->
                <div class="specification">
                  <h3 class="detail-title">Specification</h3>
                  <div class="pic">
                    <img :src="goodDetail.gnorms" alt="" />
                  </div>
                </div>

                <div class="pic">
                  <h3 class="detail-title">Products Description</h3>
                  <img class="mt10" v-for="(item, index) in descList" :src="item" :key="index" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <icondown @scrolltops="scrolltop"></icondown>
  </div>
</template>

<script>
import 'swiper/css/swiper.css';
import Swiper from 'swiper';
import { getGoodById } from '@/api/good';
import { getGoodsList } from '@/api/good';
export default {
  name: 'home',
  data() {
    return {
      top: 0,
      flag: false,
      flag1: false,
      headerflag: false,
      arr: [
        require('../assets/product/1.jpg'),
        require('../assets/product/2.jpg'),
        require('../assets/product/3.jpg'),
        require('../assets/product/4.jpg'),
        require('../assets/product/5.jpg'),
        require('../assets/product/6.jpg'),
        require('../assets/product/7.jpg'),
      ],
      current: 0,
      swipers: undefined,
      swipers1: undefined,
      productDetail: null,
      goodsDetail: null,
      goodDetail: {},
      goodsList: [],
      goodParam: {
        curPage: 1,
        numPerPage: 100,
        languageId: localStorage.getItem('languageID') || 1,
        type: '',
      },
      currentGoods: 0,
      thumList: [],
      featureList: [],
      volumeList: [],
      goodInfo: {},
    };
  },
  computed: {
    descList() {
      return this.goodDetail?.gdesc?.split(',');
    },
  },
  watch: {
    $route(to, from) {
      console.log({ to, from });
      this.getProductDetail(to.params.goodID);
    },
  },
  created() {
    this.getProductDetail(this.$route.params.goodID);
  },
  mounted() {
    console.log('aaaa');

    window.addEventListener('scroll', this.handleScroll);
    let that = this;
    that.swipers = new Swiper('#swiperIndex', {
      pagination: '.swiper-pagination',
      observer: true,
      observeParents: true,
      autoplay: {
        disableOnInteraction: false,
      },
      on: {
        slideChangeTransitionStart: function () {
          console.log(this.activeIndex);
          that.current = this.activeIndex;
        },
      },
    });
    that.swipers1 = new Swiper('#swiperIndex1', {
      pagination: '.swiper-pagination',
      observer: true,
      observeParents: true,
      slidesPerView: 'auto',
    });
  },
  methods: {
    prev() {
      // console.log('prev', this.current);
      this.swipers.slidePrev();
      this.current -= 1;
      if (this.current <= 0) {
        this.current = 0;
        this.swipers.slideTo(0);
      }
    },
    next() {
      // console.log('next', this.current);
      this.swipers.slideNext();
      this.current += 1;
      if (this.current + 1 > this.thumList.length) {
        this.current = 0;
        this.swipers.slideTo(0);
      }
    },
    changes(index) {
      this.current = index;
      this.swipers.slideTo(index);
    },
    scrolltop() {
      document.documentElement.scrollTop = 0;
    },
    link() {},
    handleScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    async getProductDetail(id) {
      try {
        let params = {
          id: id,
        };
        const res = await getGoodById(params);
        if (res) {
          this.goodDetail = res.data;
          this.thumList = this.goodDetail?.gmanyImages?.split(',');
          this.goodInfo = this.goodDetail?.goodsLanguages[0];
          this.featureList = this.goodInfo?.gfeatures?.split(',');
          this.volumeList = this.goodInfo?.gvolume?.split(',');
          this.goodParam.type = res.data?.productType;
          this.getGoodsList();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getGoodsList() {
      try {
        const res = await getGoodsList(this.goodParam);
        this.goodsList = res?.data?.data;
        this.goodsList.map((item, index) => {
          if (item.id == this.$route.params.goodID) {
            this.currentGoods = index;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    setGoodItem(item, index) {
      console.log(item, index);
      this.currentGoods = index;
      this.$router.push(`/goodInfo/${item.id}`);
      // this.currentProduct = item.productLanguages[0].name;
      // this.goodParam.type = item.productLanguages[0].proId;
      // this.getGoodsList(this.goodParam);
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.custom-detail {
  .video-detail {
    margin-top: 20px;
    margin-bottom: 24px;
  }
  .specification {
    margin-bottom: 20px;
  }

  .line {
    p {
      opacity: 0.6;
    }
  }
  .isActive {
    p {
      opacity: 1;
    }
  }
}
.swiper-pagination-bullet-active {
  background: #e3dcb2 !important;
}
.detail-title {
  font-size: 24px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #f7f8fa;
  margin-bottom: 6px;
}
.mt10 {
  margin-top: 10px;
}
</style>