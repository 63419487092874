<template>
	<div class="newsCenter-content">
		<div class="newsCenter-main">
				<div class="label"><router-link to="/">{{ $t('shouye') }}</router-link> / <router-link to="/news">{{ $t("xin-wen-zhong-xin") }}</router-link> / {{ newsContent.content.title && newsContent.content.title }}</div>
				<div class="news-content">
					<div class="title">{{newsContent.content.title}}</div>
					<div class="desc">
						<div class="date">{{ $t('date') }}：{{newsContent.ctime}}</div>
						<div class="person"></div>
					</div>
					<div class="content" v-html="newsContent.content.content"></div>
				</div>
		</div>
	</div>
</template>

<script>
import { getNewsById } from '@/api/news'
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false,
				aboutList: [
					{
						url: require('../assets/about-news1.png'),
						title: 'DDR5、PCIe5.0？硬件追新须谨慎',
						desc: 'DDR5、PCIe5.0？硬件追新须谨慎'
					},
					{
						url: require('../assets/about-news2.png'),
						title: '2021年国际存储市场简单回顾',
						desc: '存储产业战略要地：亚太地区和美国'
					},
					{
						url: require('../assets/about-news3.png'),
						title: 'DDR5、PCIe5.0？硬件追新须谨慎',
						desc: '英特尔的十二代酷睿处理器发售在即，该系列处理器的性能已经引起了不少网友的关注，'
					},
				],
				newsContent:{},
			}
		},
		mounted() {
			this.getNewsDetail(this.$route.params.newsId);
		},
		methods: {
			async getNewsDetail(id) {
				let params = {
					id,
				}
				let res = await getNewsById(params);
				this.newsContent = res.data
				this.newsContent.content = this.newsContent.newsLanguages.find(ele => ele.languageId == this.languageConfigList[localStorage.getItem('langkey')])
			}
		},
	}
</script>

<style lang='less' scope>
	.newsCenter-content {
		padding-top: 90px;
		padding-bottom: 110px;
		background-color: #111827;
		.newsCenter-main {
			width: 67%;
			height: 100%;
			margin: 0 auto;
			border-bottom: 1px solid #303B50;
			.label {
				font-size: 15px;
				font-family: Times New Roman-Regular, Times New Roman;
				font-weight: 400;
				color: #F7F7F7;
				line-height: 90px;
				a {
					color: #FFF;
				}
			}
			.news-content {
				width: 950px;
				height: 100%;
				margin: 0 auto;
				.title {
					font-size: 48px;
					font-family: Source Han Sans CN-Regular, Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 60px;
					margin-top: 50px;
					margin-bottom: 17px;
				}
				.desc {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					font-size: 18px;
					font-family: Times New Roman-Regular, Times New Roman;
					font-weight: 400;
					color: #999999;
					line-height: 41px;
					position: relative;
					margin-bottom: 80px;
					&::after {
						content: '';
						width: 950px;
						height: 1px;
						background: #303B50;
						position: absolute;
						bottom: -40px;
						left: 0;
					}
					.date {
						margin-right: 90px;
					}
				}
				.content {
					font-size: 16px;
					font-family: Times New Roman-Regular, Times New Roman;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 30px;
					margin-bottom: 120px;
					p {
						margin-bottom: 10px;
					}
				}
			}
		}
		.aboutNews-content {
			width: 75%;
			height: 100%;
			margin-bottom: 110px;
			margin: 0 auto;
			.title {
				font-size: 29px;
				font-family: Source Han Sans CN-Regular, Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 45px;
				margin-top: 37px;
				margin-bottom: 33px;
			}
			.aboutNews-list {
				width: 100%;
				height: 472px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.about-item {
					width: 30%;
					height: 472px;
					background: #0A0E17;
					img {
						width: 100%;
						height: 256px;
					}
					.sub-title {
						font-size: 1.4rem;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 1.1;
						padding-top: 32px;
						padding-left: 28px;
						margin-bottom: 2rem;
						padding-right: 28px;
					}
					.desc {
						font-size: 1.2rem;
						font-family: Times New Roman-Regular, Times New Roman;
						font-weight: 400;
						color: #999999;
						line-height: 1.1;
						padding-left: 28px;
						padding-right: 28px;
					}
					.date {
						font-size: 18px;
						font-family: Times New Roman-Regular, Times New Roman;
						font-weight: 400;
						color: #999999;
						line-height: 27px;
						padding-left: 28px;
						padding-top: 10px;
					}
				}
			}
		}
	}

	@media (max-width: 800px) {
		.newsCenter-content {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			background-color: #111827;
			padding: 0 0.2rem;
			.newsCenter-main {
				width: 100%;
				height: 100%;
				margin: 0 auto;
				border-bottom: 0px solid #303B50;
				.label {
					font-size: 0.2rem;
					font-family: Times New Roman-Regular, Times New Roman;
					font-weight: 400;
					color: #F7F7F7;
					line-height: 1;
					padding-top: 0.6rem;
				}
				.news-content {
					width: 100%;
					height: 100%;
					margin: 0 auto;
					.title {
						font-size: 0.4rem;
						font-family: Source Han Sans CN-Regular, Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 1.1;
						margin-top: 0.4rem;
						margin-bottom: 0.2rem;
					}
					.desc {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						font-size: 0.1rem;
						font-family: Times New Roman-Regular, Times New Roman;
						font-weight: 400;
						color: #999999;
						line-height: 1;
						position: relative;
						margin-bottom: 0.3rem;
						&::after {
							content: '';
							width: 950px;
							height: 0px;
							background: #303B50;
							position: absolute;
							bottom: -40px;
							left: 0;
						}
						.date {
							margin-right: 1rem;
						}
					}
					.content {
						font-size: 0.3rem;
						font-family: Times New Roman-Regular, Times New Roman;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 1.1;
						margin-bottom: 1rem;
						p {
							margin-bottom: 0.1rem;
							line-height: 0.5rem;
						}
					}
				}
			}
			.aboutNews-content {
				width: 100%;
				height: 100%;
				margin-bottom: 0.6rem;
				margin: 0 auto;
				.title {
					font-size: 0.4rem;
					font-family: Source Han Sans CN-Regular, Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 1.1;
					margin-top: 0.4rem;
					margin-bottom: 0.4rem;
				}
				.aboutNews-list {
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					.about-item {
						width: 100%;
						height: 6rem;
						background: #0A0E17;
						margin-bottom: 0.4rem;
						img {
							width: 100%;
							height: 4rem;
						}
						.sub-title {
							font-size: 0.2rem;
							font-family: Source Han Sans CN-Regular, Source Han Sans CN;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 1.2;
							padding-top: 0.1rem;
							padding-left: 0.2rem;
							margin-bottom: 0.2rem;
						}
						.desc {
							font-size: 0.2rem;
							font-family: Times New Roman-Regular, Times New Roman;
							font-weight: 400;
							color: #999999;
							line-height: 1.1;
							padding-left: 0.2rem;
						}
						.date {
							font-size: 0.2rem;
							font-family: Times New Roman-Regular, Times New Roman;
							font-weight: 400;
							color: #999999;
							line-height: 1.1;
							padding-left: 0.2rem;
							padding-top: 0.1rem;
						}
					}
				}
			}
		}
	}
</style>