<template>
	<div class="contents cultureMedia">
		<div class="content">
			<div class="banners" :style="'height: 750px;padding: 0;background-image: url(' + require('../assets/about.jpg') +')'">
				<h4>{{ $t('gong-si-wen-hua') }}</h4>
			</div>
			<div class="main_cultrue">
				<div class="m">
					<h3>{{ $t('ying-tian-shuo-shi-zhong-bing-cheng-zhuo') }}</h3>
					<ul class="cultrue-content">
						<li class="li1">
							<div class="grow">
								<p>{{ $t('fu-wu-li-nian') }}</p>
                <div class="line"></div>
								<em>{{ $t('c1') }}</em>
							</div>
						</li>
						<li class="li2" style="marginLeft: 52px;marginRight: 52px">
							<div class="grow">
								<p>{{ $t('yong-ren-li-nian') }}</p>
                <div class="line"></div>
								<em>{{ $t('ren-pin-di-yi-tai-du-di-er-neng-li-di-san') }}</em>
							</div>
						</li>
						<li class="li3">
							<div class="grow">
								<p>{{ $t('pin-zhi-li-nian') }}</p>
                <div class="line"></div>
								<em>{{ $t('mei-you-pin-zhi-bian-mei-you-wei-lai') }}</em>
							</div>
						</li>
					</ul>
				</div>
			</div>
      <div class="main_desc">
        <ul>
          <li>
            <div class="bg" :style="'background-image: url(' + require('../assets/clutrue1.png') +')'"></div>
            <div class="content right">
              <p>{{ $t('yuan-jing') }}</p>
              <em>{{ $t('c2') }}</em>
            </div>
          </li>
          <li>
            <div class="content">
              <p>{{ $t('shi-ming') }}</p>
              <em>{{ $t('c3') }}</em>
            </div>
            <div class="bg right" :style="'background-image: url(' + require('../assets/clutrue2.png') +')'"></div>
          </li>
          <li>
            <div class="bg" :style="'background-image: url(' + require('../assets/clutrue3.jpg') +')'"></div>
            <div class="content right">
              <p>{{ $t('jia-zhi-guan') }}</p>
              <em>{{ $t('c4') }}</em>
            </div>
          </li>
        </ul>
      </div>
		</div>
		<icondown @scrolltops='scrolltop'></icondown>
	</div>
</template>

<script>
	export default {
		name: 'home',
		data() {
			return {
				top: 0,
				flag: false,
				flag1: false,
				headerflag: false
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			scrolltop() {
				document.documentElement.scrollTop = 0
			},
			link() {

			},
			handleScroll() {
				if((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
					this.flag = true
				} else {
					this.flag = false
				}
			}
		},
		beforeDestroy() {
			window.removeEventListener('scroll', this.handleScroll)
		},
	}
</script>

<style lang="less" scope>
	.swiper-pagination-bullet-active {
		background: #E3DCB2 !important;
	}
	/* 最大宽度为800px样式 */
	@media (max-width: 800px) {
		.cultureMedia {
			width: 100%;
			background-color: #111827;
			.banners {
				height: 4rem !important;
			}
			.main_cultrue {
				padding-top: 1rem;
				padding-bottom: 0rem;
			}
			.banners h4 {
				font-size: 0.7rem;
			}
			.main_cultrue .m h3 {
				font-size: 0.5rem;
			}
			.main_cultrue .m .cultrue-content {
				margin-top: 1rem;
				display: flex;
				flex-direction: column;
				height: 100%;
			}
			.main_cultrue .m .cultrue-content li {
				width: 100%;
				padding: 0.5rem;
				margin-bottom: 1rem;
				.grow {
					p {
						font-size: 0.3rem;
						margin-bottom: 0.3rem;
						line-height: 0.6rem;
					}
					.line {
						width: 35%;
						height: 0.05rem;
						margin-bottom: 0.4rem;
					}
					em {
						font-size: 0.4rem;
						line-height: 0.6rem;
					}
				}
			}
			.main_desc {
				width: calc(100% - .6rem) !important;
				margin: 0 auto;
				background-color:#111827;
				ul li {
					width: 100%;
					height: 6rem;
					margin-bottom: 0.8rem;
					display: flex;
					flex-direction: column;
					.bg {
						width: 100%;
						height: 4rem;
						background-position: center;
						background-size: contain;
						background-repeat: no-repeat;
						position: relative;
					}
					.content {
						width: 80%;
						height: 3rem;
						padding: 0.4rem;
						position: absolute;
						z-index: 99 !important;
						p {
							font-size: 0.4rem;
							line-height: 1;
							margin-bottom: 0.4rem;
						}
						em {
							font-size: 0.2rem;
							line-height: 1;
						}
					}
					.right {
						margin-left: 0;
					}
				}
			}
		}
	}
</style>