<template>
  <div class="contents">
    <div class="content">
      <div class="banners" :style="'background-image: url(' + require('../assets/faqbanner.jpg') + ')'">
        <h4>{{ $t('ji-shu-zhi-chi') }}</h4>
        <div class="tabbar">
          <div class="m">
            <div class="on">
              <router-link to="/service">
                <img src="../assets/download1.png" />
                <span>{{ $t('shou-hou-fu-wu') }}</span>
              </router-link>
            </div>
            <div>
              <router-link to="/data_down">
                <img src="../assets/download.png" />
                <span>{{ $t('zi-liao-xia-zai') }}</span>
              </router-link>
            </div>
            <div>
              <router-link to="/support">
                <img src="../assets/download2.png" />
                <span>FAQ</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="main_service">
        <ul class="m">
          <li>
            <div class="t">
              <div></div>
              <span>{{ $t('shou-hou-fu-wu-xie-yi') }}</span>
            </div>
            <p>1.{{ $t('p1') }}</p>
            <p>{{ $t('p2') }}</p>
            <p>2.{{ $t('yi-xia-qing-kuang-bu-zai-san-bao-you-xiao-qi-nei') }}</p>
            <p>（1）{{ $t('san-bao-you-xiao-qi-yi-shang') }}</p>
            <p>（2）{{ $t('p3') }}</p>
            <p>（3）{{ $t('p4') }}</p>
            <p>（4）{{ $t('p5') }}</p>
            <p>（5）{{ $t('ren-wei-sun-huai') }}</p>
            <p>（6）{{ $t('bu-ke-kang-li-zao-cheng-de-sun-huai') }}</p>
            <p>（7）{{ $t('p6') }}</p>
            <p>（8）{{ $t('p7') }}</p>
            <p>（9）{{ $t('mo-sun-ling-jian-de-geng-huan') }}</p>
            <p>（10）{{ $t('p8') }}</p>
            <p>3.{{ $t('p9') }}</p>
            <p>4.{{ $t('chan-pin-zai-guo-jia-bao-xiu-qi-nei') }}</p>
            <p>5.{{ $t('p10') }}</p>
          </li>
        </ul>
      </div>
    </div>
    <icondown @scrolltops="scrolltop"></icondown>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      top: 0,
      flag: false,
      flag1: false,
      headerflag: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrolltop() {
      document.documentElement.scrollTop = 0;
    },
    link() {},
    handleScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style>
.swiper-pagination-bullet-active {
  background: #e3dcb2 !important;
}
</style>