<template>
  <div class="contents custom-odm">
    <div class="content">
      <div
        class="banners"
        :style="'background-image: url(' + require('../assets/odm5.jpg') + ');height: 750px;padding: 0;'"
      >
        <h4>OEM/ODM</h4>
      </div>
      <div class="main_advantages">
        <div class="m">
          <h3>{{ $t('ding-zhi-you-shi') }}</h3>
          <ul>
            <li>
              <a href="#">
                <img src="../assets/odm4.png" />
                <p>{{ $t('ding-zhi-hua-jie-jue-fang-an') }}</p>
                <div class="zz">
                  <div>
                    <span>{{ $t('ding-zhi-hua-jie-jue-fang-an') }}</span>
                    <em>{{ $t('dzhjjfa-intro') }}</em>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../assets/odm3.png" />
                <p>{{ $t('duo-yuan-hua-chan-pin-sheng-chan-xian') }}</p>
                <div class="zz">
                  <div>
                    <span>{{ $t('duo-yuan-hua-chan-pin-sheng-chan-xian') }}</span>
                    <em>{{ $t('dyhscx-intro') }}</em>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../assets/odm1.png" />
                <p>{{ $t('kuai-su-shi-chang-hua') }}</p>
                <div class="zz">
                  <div>
                    <span>{{ $t('kuai-su-shi-chang-hua') }}</span>
                    <em>{{ $t('kssch-intro') }}</em>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../assets/odm.png" />
                <p>{{ $t('geng-ju-cheng-ben-you-shi-yu-xiao-yi') }}</p>
                <div class="zz">
                  <div>
                    <span>{{ $t('geng-ju-cheng-ben-you-shi-yu-xiao-yi') }}</span>
                    <em>{{ $t('cbys-intro') }}</em>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../assets/odm1.png" />
                <p>{{ $t('chan-pin-gong-ying-bao-zhang') }}</p>
                <div class="zz">
                  <div>
                    <span>{{ $t('chan-pin-gong-ying-bao-zhang') }}</span>
                    <em>{{ $t('cpgybz-intro') }}</em>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <img src="../assets/odm2.png" />
                <p>{{ $t('zeng-zhi-fu-wu') }}</p>
                <div class="zz">
                  <div>
                    <span>{{ $t('zeng-zhi-fu-wu') }}</span>
                    <em>{{ $t('zzfw-intro') }}</em>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="main_option">
        <div class="m">
          <h3>{{ $t('ding-zhi-xuan-xiang') }}</h3>
          <ul>
            <li>
              <a href="#">
                <div class="icon">
                  <img src="../assets/odms5.png" />
                  <span>{{ $t('xin-pian-gui-ge') }}</span>
                </div>
                <div class="share">
                  <img src="../assets/odmpic_03.jpg" />
                  <p>{{ $t('xin-pian-gui-ge') }}</p>
                  <span>{{ $t('xpgg-intro') }}</span>
                  <span>{{ $t('xpgg-intro2') }}</span>
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="icon">
                  <img src="../assets/odm7.png" />
                  <span>{{ $t('shu-ju-jie-kou') }}</span>
                </div>
                <div class="share">
                  <img src="../assets/odms4.png" />
                  <p>{{ $t('shu-ju-jie-kou') }}</p>
                  <span>{{ $t('sjjk-intro') }}</span>
                  <!-- <span>闪存：三星、东芝、镁光、英特尔、闪迪和长江存储等（关联产品：固态硬盘/内存条/U盘 ）。</span> -->
                </div>
              </a>
            </li>
            <li>
              <a href="#">
                <div class="icon">
                  <img src="../assets/odmss.png" />
                  <span>{{ $t('pin-pai-she-ji') }}</span>
                </div>
                <div class="share">
                  <img src="../assets/odms3.png" />
                  <p>{{ $t('pin-pai-she-ji') }}</p>
                  <span>{{ $t('ppsj-intro') }}</span>
                  <!-- <span>闪存：三星、东芝、镁光、英特尔、闪迪和长江存储等（关联产品：固态硬盘/内存条/U盘 ）。</span> -->
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="main_process">
        <div class="m">
          <h3>{{ $t('ding-zhi-liu-cheng') }}</h3>
          <img :src="require('../assets/ODMOEM-2.png')" class="box" v-if="isChinese" />
          <img :src="require('../assets/ODMOEM.png')" class="box" v-else />
        </div>
      </div>
    </div>
    <icondown @scrolltops="scrolltop"></icondown>
  </div>
</template>

<script>
export default {
  name: 'home',
  data() {
    return {
      top: 0,
      flag: false,
      flag1: false,
      headerflag: false,
      langKey: localStorage.getItem('langkey') || 'zh',
      isChinese: localStorage.getItem('langkey') == 'zh'
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    scrolltop() {
      document.documentElement.scrollTop = 0;
    },
    link() {},
    handleScroll() {
      if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) >= 50) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.custom-odm {
  .zz {
    overflow: hidden;
    span {
      display: inline-block;
      white-space: nowrap;
      font-size: 20px;
    }
    div {
      padding: 0 5%;
    }
    em {
    }
  }
}
.swiper-pagination-bullet-active {
  background: #e3dcb2 !important;
}
</style>